<template>
  <div class="fab-wrapper">
    <span class="fab-button">
      <slot name="icon"
        ><b-icon class="content" font-scale="1.5" :icon="icon"> </b-icon
      ></slot>
    </span>
    <ul class="fab-item-list">
      <li>
        <slot name="option1"> </slot>
      </li>
      <li>
        <slot name="option2"> </slot>
      </li>
      <li>
        <slot name="option3"> </slot>
      </li>
      <li>
        <slot name="option4"> </slot>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    icon: { type: String, default: 'three-dots-vertical' }
  }
}
</script>
