export default class Timer {
  constructor(interval, cb) {
    this.interval = interval
    this.current = undefined
    this.cb = cb
  }

  stop() {
    if (this.current) {
      clearTimeout(this.current)
      this.current = undefined
    }
  }

  start() {
    this.stop()
    this.current = setTimeout(this.cb, this.interval)
  }
}
