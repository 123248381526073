class TilesLayout {
  constructor() {
    this.itemsDimensions = []
    this.itemsPositions = []
    this.columnsHeight = []
    this.contentHeight = 100
    this.containerWidth = 0
    this.container = undefined
  }

  attach(container) {
    this.container = container
    this.handleResize()
  }
  reset() {
    this.itemsDimensions = []
    this.resetPosition()
    this.handleResize()
  }
  resetPosition() {
    this.itemsPositions = []
    this.columnsHeight = []
    this.contentHeight = 100
    this.containerWidth = 0
  }

  position(index, total, dimension) {
    if (this.itemsPositions[index]) {
      return this.itemsPositions[index]
    }
    this.itemsDimensions[index] = dimension
    //compute the number of columns

    let columns = 4
    if (this.containerWidth < 600) {
      columns = 1
    } else if (this.containerWidth < 800) {
      columns = 2
    } else if (this.containerWidth < 1000) {
      columns = 3
    }

    let paddingLeft = 10
    let paddingTop = 20

    let columnWidth =
      (this.containerWidth - (columns - 1) * paddingLeft) / columns
    let height = Math.ceil(columnWidth / (dimension.width / dimension.height))

    let column = this._selectBestColumn(height, columns)

    let position = {}
    position['w'] = parseInt(columnWidth)
    position['left'] = column * columnWidth + paddingLeft * column

    if (this.columnsHeight[column] == undefined) {
      position['top'] = paddingTop
    } else {
      //let aboveIndex = (row - 1) * columns + column
      let abovePosition = this.columnsHeight[column]
      position['top'] = paddingTop + abovePosition['top'] + abovePosition['h']
    }

    position['h'] = parseInt(height)
    this.itemsPositions.push(position)

    this.columnsHeight[column] = position

    //is last item ?
    if (index == total - 1) {
      let i
      for (i in this.columnsHeight) {
        var p = this.columnsHeight[i]
        this.contentHeight = Math.max(this.contentHeight, p['top'] + p['h'])
      }
    }

    return position
  }

  handleResize() {
    if (this.containerWidth != this.container.offsetWidth) {
      this.resetPosition()
      this.containerWidth = this.container.offsetWidth
      this._positionElements()
    }
  }
  _positionElements() {
    this.itemsDimensions.forEach((dimension, index, dms) => {
      this.position(index, dms.length, dimension)
    })
  }
  _selectBestColumn(height, columnsCount) {
    let current
    let minHeight = Number.MAX_SAFE_INTEGER
    for (var c = 0; c < columnsCount; c++) {
      if (this.columnsHeight[c] == undefined) {
        return c
      } else {
        let h = this.columnsHeight[c].top + this.columnsHeight[c].h
        if (h < minHeight && minHeight - h > parseInt(height * 0.5)) {
          minHeight = h
          current = c
        }
      }
    }
    return current
  }
}

export default TilesLayout
