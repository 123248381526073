<template>
  <div
    @click="iselected = !iselected"
    v-bind:class="[{ selected: selectable ? iselected : true }, size]"
    class="taggr-tag main-app-font"
    :style="`--color: ${computedColor}`"
  >
    {{ value }}
    <b-icon-dash-circle-fill
      v-if="remove"
      @click="deleteTag"
      class="remove-button"
    />
  </div>
</template>
<script>
import ColorUtils from '../../utils/ColorUtils'
export default {
  name: 'tag',
  data() {
    return {
      iselected: false
    }
  },
  mounted() {
    this.iselected = this.selected
  },
  props: {
    size: {
      type: String, //xs, s, m, l, xl
      default: 'm'
    },
    selectable: {
      type: Boolean,
      default: true
    },
    remove: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: 'no-value'
    },
    color: {
      type: String,
      default: undefined
    },
    onChanged: {
      type: Function,
      default: function () {}
    },
    observer: null
  },
  methods: {
    deleteTag() {
      if (this.observer == null) {
        this.$parent.$emit('delete-tag', this.value)
      } else {
        this.observer.$emit('delete-tag', this.value)
      }
    }
  },
  computed: {
    computedColor() {
      return this.color ? this.color : ColorUtils.randomColor().get(this.value)
    }
  },
  watch: {
    selected(current) {
      this.iselected = current
    },
    iselected(current) {
      this.onChanged(this.value, current)
    }
  }
}
</script>
<style scoped>
.form-control:focus {
  box-shadow: none !important;
  border-color: #ced4da !important;
}

.taggr-tag {
  font-family: BlinkMacSystemFont, San Francisco, Helvetica Neue, Helvetica;

  min-width: 3rem;
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 2px;
  padding-bottom: 2px;
  display: inline-block;
  --color: #0000;
  color: var(--color);
  background: white;
  margin: 0.3rem;
  border-color: var(--color);
  border-style: solid;
  border-width: 2px;
  border-radius: 15px;
  text-align: center;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  user-select: none;
}
.s {
  min-width: 2rem;
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 1px;
  padding-bottom: 1px;
  font-size: 0.7rem;
  margin: 0.18rem;
}
.taggr-tag.selected {
  --color: #ffffff;
  color: white;
  background: var(--color);
  border-color: var(--color);
  display: inline-block;
  border-style: solid;
  text-align: center;
}
.remove-button {
}
.remove-button:hover {
  cursor: pointer;
}
</style>
