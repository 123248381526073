<template>
  <b-container fluid class="mt-5 ml-0 mr-0 w-100">
    <div>
      <!-- Profile widget -->
      <div class="bg-white shadow rounded overflow-hidden">
        <div class="px-4 pt-0 pb-4 cover">
          <div class="media align-items-end profile-head">
            <div class="profile mr-3">
              <img
                src="https://placekitten.com/300/300"
                alt="..."
                width="130"
                class="rounded mb-2 img-thumbnail"
              />
            </div>
            <div class="media-body mb-5">
              <h4 class="mt-0 mb-0 text-white">
                {{ user.firstName }} {{ user.lastName }}
              </h4>
              <p class="small mb-4 text-white">
                <i class="fas fa-map-marker-alt mr-2"></i>{{ user.email }}
              </p>
            </div>
          </div>
        </div>
        <div class="bg-light p-4 d-flex justify-content-end text-center">
          <ul class="list-inline mb-0">
            <li class="list-inline-item">
              <h5 class="font-weight-bold mb-0 d-block">
                {{ totalImagesCount }}
              </h5>
              <small class="text-muted">
                <i class="fas fa-image mr-1"></i>Images</small
              >
            </li>
            <li class="list-inline-item">
              <h5 class="font-weight-bold mb-0 d-block">745</h5>
              <small class="text-muted">
                <i class="fas fa-user mr-1"></i>Videos</small
              >
            </li>
          </ul>
        </div>
        <div class="px-4 py-3">
          <h5 class="mb-0">Data Usage</h5>
          <div class="p-4 rounded shadow-sm bg-light">
            <h1 class="font-italic mb-0">{{ totalUsedData }}</h1>
            <p class="font-italic mb-0">uploaded so far</p>
          </div>
        </div>
        <div class="py-4 px-4">
          <SharesTable />
        </div>
      </div>
    </div>
    <div class="w-100 text-right justify-content-right text-muted">
      <small> {{ version }} </small>
    </div>
  </b-container>
</template>
<script>
import api from '../api'
import config from '../config'
import { mapState } from 'vuex'
import { getReadableFileSizeString } from '../utils'
import SharesTable from '../components/SharesTable'
export default {
  components: {
    SharesTable
  },
  data() {
    return {
      stats: []
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user2,
      isLoggedIn: 'isLoggedIn'
    }),
    totalUsedData() {
      let total = 0
      this.stats.forEach((item) => {
        total = total + item.size
      })

      return getReadableFileSizeString(total)
    },
    totalImagesCount() {
      let total = 0
      this.stats.forEach((item) => {
        total = total + item.count
      })

      return total
    },
    imageUrl() {
      return ''
    },
    version() {
      return 'v' + config.version()
    }
  },
  mounted() {
    let _this = this
    api.getStats().then((stats) => {
      _this.stats = stats
    })
    this.$store.dispatch('auth/userInfo2')
  }
}
</script>
<style scoped>
.profile-head {
  transform: translateY(5rem);
}

.cover {
  background-image: url(https://images.unsplash.com/photo-1530305408560-82d13781b33a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1352&q=80);
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
