<template>
  <div
    class="thumb-container"
    :style="`--width: ${width}px; --height: ${height}px;`"
    @click.stop
  >
    <b-icon
      v-if="isSelectModeOn == false && uploaded == false && isError == false"
      @click="onCloseClickedHandler"
      @click.stop
      class="thumb-button"
      icon="x-circle-fill"
      aria-label="Help"
    ></b-icon>
    <b-icon
      v-else-if="isSelected && isError == false"
      @click="toggleSelected"
      @click.stop
      class="thumb-button select selected"
      icon="check-circle-fill"
      aria-label="Help"
    ></b-icon>
    <b-icon
      v-else-if="isError == false"
      @click="toggleSelected"
      @click.stop
      class="thumb-button select"
      icon="check-circle"
      aria-label="Help"
    ></b-icon>
    <b-icon
      v-show="isError"
      class="thumb-button"
      variant="danger"
      icon="exclamation-circle"
      v-b-tooltip.focus
      :title="errorMessage"
      aria-label="error"
    ></b-icon>
    <loader-svg v-if="loading" />
    <img
      v-else
      class="thumb-image"
      v-bind:class="{ selected: showSelected }"
      :style="`--width: ${width}px; --height: ${height}px;`"
      thumbnail
      :src="src"
      alt=""
    />
    <div :style="`--width: ${progress}%;`" class="thumb-progress" />
    <b-tooltip target="tooltip-error" triggers="hover">
      {{ errorMessage }}
    </b-tooltip>
  </div>
</template>
<script>
import api from '../../api'
import TFile from '../../utils/TFile'
import imageTools from '../../utils/ImageTools'
import LoaderSvg from '../partials/LoaderSvg'
import { errors } from '../../api/const'
export default {
  name: 'image-thumbnail',
  components: {
    LoaderSvg
  },
  data() {
    return {
      uploaded: false,
      currentPromise: undefined,
      progress: 0,
      loading: true,
      isSelected: false,
      isError: false,
      errorMessage: undefined,
      src: ''
    }
  },
  props: {
    file: TFile,
    width: {
      default: 150,
      type: Number
    },
    height: {
      default: 150,
      type: Number
    },
    selectMode: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    },
    onCloseClicked: {
      type: Function,
      default: function (tmb) {
        console.log('close icon clicked on ' + tmb.file.name)
      }
    },
    onSelectedClicked: {
      type: Function,
      default: function (data, component, selected) {
        console.log(data + component + selected)
      }
    }
  },
  computed: {
    isSelectModeOn() {
      return this.selectMode
    },
    showSelected() {
      return this.selectMode == true && this.isSelected == true
    }
  },
  mounted() {
    this.isSelected = this.selected
    var _this = this
    this.currentPromise = imageTools
      .crop(this.file, { width: 600, height: 600 })
      .then(
        (objectUrl) => {
          _this.src = objectUrl
          _this.loading = false
        },
        (failure) => {
          console.log('image processing failed with reason :' + failure)
          _this.loading = false
        }
      )
      .progress((progress) => console.log('loading image progress ', progress))
      .catch((error) => {
        _this.loading = false
        console.log('image processing exception with reason :' + error)
      })
  },
  beforeDestroy() {
    imageTools.revoke(this.src)
  },
  watch: {
    selected(current) {
      this.isSelected = current
    },
    loading(current) {
      if (current == false) {
        this.upload()
      }
    }
  },
  methods: {
    toggleSelected() {
      if (this.selectMode == false) return
      this.isSelected = !this.isSelected
      if (this.onSelectedClicked) {
        this.onSelectedClicked(this.file, this, this.isSelected)
      }
    },
    onCloseClickedHandler() {
      if (this.currentPromise) {
        this.currentPromise.cancel()
        this.currentPromise = undefined
      }
      this.onCloseClicked(this)
    },
    upload() {
      this.currentPromise = api.uploadMedia(this.file)
      this.currentPromise
        .then(this._uploadConfirmed, this._uploadRejected)
        .progress((progress) => {
          this.progress = progress
        })
        .catch((data) => {
          console.log('Upload failed', data)
          this.progress = 0
          this.uploaded = false
        })
        .finally(function () {
          console.log('finally called')
        })
    },
    _uploadConfirmed(data) {
      console.log('upload Ok', data)
      this.progress = 100
      this.uploaded = true
    },
    _uploadRejected(data) {
      console.log('upload rejected', data)
      if (errors.ERROR_REQUEST_MEDIA_ALREADY_UPLOADED == data.error) {
        this.progress = 100
        this.uploaded = true
        this.isError = true
        this.errorMessage = 'This file was already uploaded.'
      } else if (errors.ERROR_REQUEST_NETWORK_ERROR == data.error) {
        this.progress = 0
        this.isError = true
        this.uploaded = false
        this.errorMessage = "The server can't be contacted right now."
      }
    },
    _uploadProgress(progress) {
      console.log('progress ', progress)
      this.progress = progress
    }
  }
}
</script>
<style scoped></style>
