<template>
  <div
    class="overflow-hidden pl-3 pr-3 min-vh-100 bg-light dashboard-background"
  >
    <div class="container-fluid p-0 dashboard-content">
      <recent-uploads
        v-if="showRecent && searchMode == false"
        :files="media.latest"
        title="Recent Activity"
        subtitle="Images and videos uploaded or shared with you in the last week."
      />
      <recent-uploads
        v-else
        :files="media.all"
        title="All Your Media"
        :subtitle="subtitle"
      />
    </div>
  </div>
</template>
<script>
import RecentUploads from '../components/RecentUploads'

import config from '../config'

export default {
  components: {
    RecentUploads
  },
  data() {
    return {
      showRecent: config.getFeatures().dashboard.showRecent,
      media: {
        search: [],
        latest: [],
        all: []
      }
    }
  },
  methods: {
    subtitle: function (count) {
      return `Those are all your ${count} images and videos you uploaded or shared with you`
    }
  },
  mounted() {}
}
</script>
