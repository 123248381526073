export default class TFile {
  constructor(file) {
    this.file = file
    this.md5 = undefined
    this.tags = new Set()
    this.typeClass = file.type.split('/')[0]
  }

  name() {
    return this.file.name
  }
  class() {
    // return video , image , audio ....
    return this.typeClass
  }
  key() {
    return `${this.file.name}-${this.file.size}`
  }
  type() {
    //return image/png or image/jpeg
    return this.file.type
  }
  size() {
    return this.file.size
  }
  addTag(tag) {
    this.tags.add(tag)
  }
  removeTag(tag) {
    this.tags.remove(tag)
  }
  getTags() {
    return this.tags.values()
  }
  setTags(tags) {
    this.tags = [...tags]
  }

  setMD5(md5) {
    this.md5 = md5
  }

  getMD5() {
    return this.md5
  }

  equals(file) {
    return this.key() == file.key()
  }
}
