<template>
  <b-container fluid class="upload-container d-flex flex-column">
    <!-- HEADER -->
    <div class="header w-100">
      <span>Upload to taggr</span>
      <div class="text-secondary small float-right">
        upload will start automatically
      </div>
      <!-- <b-form-checkbox class="float-right" v-model="uploadOnly">
        upload only
      </b-form-checkbox> -->
      <b-container
        v-if="!uploadOnly"
        class="d-flex upload-tabs-container justify-content-center"
      >
        <object :data="navigationImage()" type="image/svg+xml"></object>
      </b-container>

      <!-- label at the top center  -->
      <b-container
        v-if="!uploadOnly"
        class="text-secondary small w-100 justify-content-center p-0"
      >
        <span v-show="currentStep == 1">
          Start with <strong>uploading</strong> a few images
        </span>
        <span v-show="currentStep == 2">
          <strong>Select</strong> one or more images
          <b-icon-arrow-right-short />
          <strong>Tag</strong> and hit <strong>Apply</strong> to save.
        </span>
        <span v-show="currentStep == 3">
          <strong>Upload</strong> to taggr <b-icon-upload />
        </span>
      </b-container>
    </div>
    <!-- END OF HEADER -->
    <!-- Start tab content -->
    <div class="upload-tab-content-container flex-grow-1">
      <!-- start of files and thumbnail section -->
      <div
        v-show="currentStep == 1 || currentStep == 2"
        @click="openFileSelector"
        @drop.prevent="onFilesDropped"
        @dragover.prevent
        :style="`--width: ${step1Width}`"
        class="upload-tab-content-files-dropbox justify-content-center"
      >
        <input
          ref="fileInput"
          @change="handleInputFileChanged"
          class="hide-file-input"
          type="file"
          multiple
          accept="image/*,video/*"
          data-test="uploader-input"
        />
        <section v-if="showDropBoxContent">
          <object
            class="drop-box-images-icon"
            data="img/icons/images.svg"
            type="image/svg+xml"
          ></object>
          <div class="dropbox-main-headline text-center">
            <h4>Drag and drop folders and individual images</h4>
            <p class="text-muted">
              or <span class="text-dark"><strong>Browse</strong></span> to pick
              one
            </p>
            <p class="text-secondary small">
              <br /><br />Don't upload illegal material.<br />See our
              <b-link class="font-weight-bold">terms of service</b-link> for
              details.
            </p>
          </div>
        </section>
        <section v-else>
          <image-thumbnail
            :selected="selectedFileNames.has(file.key())"
            :selectMode="currentStep == 2"
            :onSelectedClicked="onThumbSelectedClicked"
            :onCloseClicked="onThumbCloseClicked"
            v-for="file in files"
            :key="file.key()"
            :file="file"
          />
        </section>
      </div>
      <!-- end of files and thumbnail section -->

      <!-- Start of TAG section -->
      <div
        v-if="currentStep == 2"
        class="upload-tab-content-tag justify-content-center"
      >
        <b-container class="mt-1">
          <b-input-group
            class="mb-4 mt-4 w-100 new-tag-control-input-container"
          >
            <b-form-input
              size="sm"
              placeholder="add a new tag and press ⮐"
              type="text"
              autofocus
              ref="addTagInput"
              v-on:keyup.enter="onTagEnter"
              class="new-tag-control-input"
              v-model="newTag"
            >
              <span name="placeholder"></span>
            </b-form-input>
            <b-input-group-append>
              <b-button
                @click="newTag = ''"
                :disabled="this.newTag.length == 0"
                link
                size="sm"
                class="border-0"
                variant="outline-secondary"
              >
                <b-icon icon="x" aria-label="Help"></b-icon>
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <p>
            <tag
              v-for="tag in tags"
              :key="tag"
              :ref="`tag${tag}`"
              :value="tag"
              :onChanged="onTagChanged"
              :selected="selectedTags.indexOf(tag) >= 0"
              :color="getTagColor(tag)"
            />
          </p>
        </b-container>
      </div>
      <!-- end step 2 -->

      <div v-if="currentStep == 3" class="upload-tab-content-upload">
        <Progress
          class="float-right"
          :radius="50"
          :strokeWidth="10"
          strokeColor="#FF00AA"
          value="100"
        >
          <template v-slot:footer>
            <b>Uploaded</b>
          </template>
        </Progress>
      </div>
    </div>
    <!-- start of bottom navigation -->
    <b-container fluid class="upload-tab-button-container d-block">
      <b-row>
        <b-col class="p-0 align-self-center">
          <b-button
            v-if="currentStep > 1"
            class="m-1 btn-sm text-secondary float-left"
            @click="prev"
            variant="link"
            ><b-icon-arrow-left-short />back</b-button
          >
          <div
            v-if="currentStep == 1"
            class="text-secondary small align-middle w-100"
          >
            {{ uploadInfoMessage }}
          </div>
        </b-col>
        <b-col class="p-0 align-self-center">
          <a
            id="option_button"
            @click="apply"
            v-bind:class="{ disabled: selectedFiles.length == 0 }"
            v-if="currentStep == 2 || currentStep == 3"
            class="btn btn-sm m-1 w-100 btn-success"
            ><span>{{ applyMainActionButtonLabel }}</span></a
          >
        </b-col>
        <b-col class="p-0 align-self-center">
          <b-button
            v-if="uploadOnly == false && (currentStep == 1 || currentStep == 2)"
            class="m-1 float-right btn-sm text-secondary"
            @click="next"
            variant="link"
            >next<b-icon-arrow-right-short
          /></b-button>
        </b-col>
      </b-row>

      <!--
            -->
    </b-container>
  </b-container>
</template>
<script>
import CollectionUtils from '../utils/CollectionUtils'
import StringUtils from '../utils/StringUtils'
import { getReadableFileSizeString } from '../utils'
import TFile from '../utils/TFile'
import TFileUtils from '../utils/TFileUtils'
import ColorUtils from '../utils/ColorUtils'
import { parseFolder } from '../utils'
import ImageThumbnail from './partials/ImageThumbnail'
import Tag from './partials/Tag'
//import Progress from 'easy-circular-progress'
import api from '../api'

export default {
  name: 'upload-tabs',
  components: {
    ImageThumbnail,
    Tag
    // Progress
  },
  data() {
    return {
      uploadOnly: true,
      newTag: '',
      uploadInfoMessage: '',
      colorProvider: ColorUtils.randomColor(),
      fileNames: new Set(),
      files: [],
      selectedFiles: [],
      selectedFileNames: new Set(),
      bytesCount: 0,
      uploadedBytesCount: 0,
      currentStep: 1,
      step1Width: '100%',
      tags: new Set([
        'one',
        'had',
        'by',
        'word',
        'but',
        'not',
        'what',
        'all',
        'were',
        'we',
        'when',
        'your',
        'can',
        'said',
        'there',
        'use',
        'an',
        'each',
        'which',
        'she',
        'do',
        'how',
        'their',
        'if',
        'will',
        'up',
        'other',
        'about',
        'out',
        'many',
        'then',
        'them',
        'these',
        'so',
        'some',
        'her',
        'would',
        'make',
        'like',
        'him',
        'into',
        'time',
        'has',
        'look',
        'two',
        'more',
        'write',
        'go',
        'see',
        'number',
        'no',
        'way',
        'could',
        'people',
        'my',
        'than',
        'first',
        'water',
        'been',
        'call',
        'who',
        'oil',
        'its',
        'now',
        'find',
        'long',
        'down',
        'day',
        'did',
        'get',
        'come',
        'made',
        'may',
        'part',
        'apple',
        'orange',
        'grape',
        'car',
        'holiday',
        'mountain',
        'sea',
        'wave',
        'sun',
        'rome',
        'dublin',
        'cat',
        'plant',
        'food',
        'friends',
        'family',
        'apple',
        'orange',
        'grape',
        'car',
        'holiday',
        'mountain',
        'sea',
        'wave',
        'sun',
        'rome',
        'dublin',
        'cat',
        'plant',
        'food',
        'friends',
        'family'
      ]),
      selectedTags: []
    }
  },
  mounted() {},
  computed: {
    showDropBoxContent() {
      return this.files.length == 0
    },
    showApplyButtons() {
      return this.currentStep == 2 && this.selectedFiles.length > 0
    },
    applyMainActionButtonLabel() {
      if (this.currentStep == 3) {
        return 'Upload Now'
      }

      if (this.selectedFiles.length == 0) {
        return 'Apply Tags'
      } else {
        if (this.selectedTags.length > 0) {
          return `Apply ${this.selectedTags.length} ${StringUtils.pluralize(
            'Tag',
            this.selectedTags.length
          )} to ${this.selectedFiles.length} ${StringUtils.pluralize(
            'Image',
            this.selectedFiles.length
          )}`
        } else {
          return `Clear Tags from ${
            this.selectedFiles.length
          } ${StringUtils.pluralize('Image', this.selectedFiles.length)}`
        }
      }
    }
  },
  methods: {
    onLightBoxClose() {
      if (api.pendingUploadsCount() > 0) {
        if (
          !confirm(
            'Are you sure you want to exit, you will have to restart from scratch?'
          )
        ) {
          return false
        }
      }
      api.cancelPendingUploads()
      this._utilsResetState()
      return true
    },
    getTagColor(tag) {
      return this.colorProvider.get(tag)
    },
    openFileSelector() {
      if (this.files.length == 0) {
        this.$refs.fileInput.click()
      }
    },
    onTagChanged(tag, selected) {
      if (selected) {
        this.selectedTags = CollectionUtils.pushUnique(this.selectedTags, tag)
      } else {
        this.selectedTags = CollectionUtils.remove(this.selectedTags, tag)
      }
    },
    onTagEnter() {
      if (this.newTag && this.newTag.length > 0) {
        this.newTag = this.newTag.toLowerCase().trim()
        let has = this.tags.has(this.newTag)
        this.tags.add(this.newTag)
        CollectionUtils.pushUnique(this.selectedTags, this.newTag)
        if (has == true) {
          this.$refs[`tag${this.newTag}`].selected = true
        }
        this.newTag = ''
      }
    },
    handleInputFileChanged(event) {
      const _this = this
      event.target.files.forEach((file) => {
        _this.addFile(file)
      })
    },
    onFilesDropped(event) {
      var items = event.dataTransfer.items

      for (var i = 0; i < items.length; i++) {
        // webkitGetAsEntry is where the magic happens
        var item = items[i].webkitGetAsEntry()
        this.handleSingleFile(item)
      }
    },
    addFile(f) {
      let file = new TFile(f)

      if (!TFileUtils.isFileTypeSupported(file)) {
        if (TFileUtils.getSupportedFileClass(file)) {
          this.$bvToast.toast(
            `Can't upload ${file.name()}, ${file.class()} type not supported.`,
            {
              title: `File type ${file.type()} not supported`,
              autoHideDelay: 4000,
              appendToast: true
            }
          )
        }

        return
      }

      if (this.fileNames.has(file.key()) == false) {
        this.files.push(file)
        this.fileNames.add(file.key())
        this.bytesCount += file.size()
      }
    },
    removeFile(file) {
      this.fileNames.delete(file.key())
      this.selectedFileNames.delete(file.key())

      this.files = this.files.filter((f) => {
        return f.key() != file.key()
      })

      this.bytesCount -= file.size()
    },
    handleSingleFile(item) {
      let _this = this
      if (item) {
        parseFolder(item, function (f) {
          _this.addFile(f)
        })
      }
    },
    onThumbCloseClicked(tmb) {
      this.removeFile(tmb.file)
    },
    navigationImage() {
      return `img/upload-step-${this.currentStep}.svg`
    },
    next() {
      if (this.currentStep < 3) {
        this.currentStep++
      }
    },
    prev() {
      if (this.currentStep > 1) {
        this.currentStep--
      }
    },
    reset() {
      this.selectedFiles = []
      this.selectedTags = []
      this.selectedFileNames.clear()
      this.$forceUpdate()
    },
    apply() {
      let _this = this
      this.selectedFiles.forEach((f) => {
        f.setTags(_this.selectedTags)
      })
    },
    onThumbSelectedClicked: function (data, component, selected) {
      if (selected) {
        this.selectedFileNames.add(data.key())
        this.selectedFiles.push(data)
      } else {
        this.selectedFileNames.delete(data.key())
        this.selectedFiles = this.selectedFiles.filter((f) => {
          return f.equals(data) == false
        })
      }
      //this.$forceUpdate();
    },
    _utilsResetState() {
      this.newTag = ''
      this.uploadInfoMessage = ''
      this.fileNames = new Set()
      this.files = []
      this.selectedFiles = []
      ;(this.selectedFileNames = new Set()),
        (this.bytesCount = 0),
        (this.currentStep = 1),
        (this.selectedTags = [])
    },
    _utilsShowTotalAddedFiles() {
      if (this.files.length > 0) {
        const uploadSize = getReadableFileSizeString(this.bytesCount)
        const file = this.files.length == 1 ? 'file' : 'files'
        this.uploadInfoMessage = `${this.files.length} ${file} added - ${uploadSize}`
      } else {
        this.uploadInfoMessage = 'no files added'
      }
    },
    _utilsShowTotalSelectedFiles() {
      if (this.selectedFiles.length == 1) {
        this.uploadInfoMessage = '1 file selected'
      } else if (this.selectedFiles.length > 1) {
        this.uploadInfoMessage = `${this.selectedFiles.length} files selected.`
      } else {
        this.uploadInfoMessage = `-`
      }
    }
  },
  watch: {
    selectedFiles() {
      this._utilsShowTotalSelectedFiles()
    },
    files() {
      this._utilsShowTotalAddedFiles()
    },
    currentStep(current) {
      if (current == 3) {
        let _this = this
        this.uploadedBytesCount = 0
        setInterval(function () {
          _this.uploadedBytesCount += 500
        }, 500)
      } else if (current == 2) {
        this._utilsShowTotalSelectedFiles()
        this.step1Width = '50%'
      } else {
        this._utilsShowTotalAddedFiles()
        this.step1Width = '100%'
      }
    }
  }
}
</script>
<style scoped>
.controls {
  position: relative;
  height: 6%;
}

.dropbox-main-headline {
  position: absolute;
  top: 60%;
  width: 100%;
}

.drop-box-images-icon {
  width: 150px;
  height: 150px;
  /* Center vertically and horizontally */
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -75px 0 0 -75px;
}

.hide-file-input {
  position: absolute;
  top: -100px;
  left: -100px;
  width: 1px;
  height: 1px;
  z-index: -1;
}
.tags-container {
  width: 100%;
  position: relative;
  background: tomato;
  display: inline-block;
}

.new-tag-control-input-container {
  border-bottom: 1px solid lightgray;
}
.new-tag-control-input,
.new-tag-control-input::before,
.new-tag-control-input:focus {
  border: 0 !important;
  box-shadow: none !important;
  outline: none !important;
}
</style>
