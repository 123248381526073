<template>
  <b-modal
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
    centered
    :title="title"
    size="md"
    ref="md"
  >
    <b-container fluid>
      {{ message }}
    </b-container>
    <template #modal-footer>
      <div class="w-100 justify-content-end d-flex">
        <b-button
          class="mr-1"
          :disabled="status != 'idle'"
          variant="secondary"
          size="sm"
          @click="hide()"
        >
          Cancel
        </b-button>
        <b-button
          :disabled="status != 'idle'"
          variant="primary"
          size="sm"
          @click="start()"
        >
          Create
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import api from '../../api'
import Timer from '../../utils/Timer'
import config from '../../config'

export default {
  name: 'link-media-modal',
  components: {},
  data() {
    return {
      ops: undefined,
      link: undefined,
      message:
        'Create a link you can use to share your files on social media and track how often are opened.',
      status: 'idle', //deleting, done
      localCopy: function () {
        return []
      },
      closeTimer: undefined
    }
  },
  props: {
    media: Array
  },
  mounted() {
    const _this = this
    this.closeTimer = new Timer(2000, function () {
      _this.hide()
    })
  },
  methods: {
    start() {
      this.status = 'creating'
      api.createShortLink(this.localCopy).then((link) => {
        this.link = link
        this.message = config.getShortLink(link.shortCode)
      })
    },
    show() {
      this.status = 'idle'
      this.localCopy = Array.from(this.media).map((m) => {
        return m.media.id
      })

      this.$refs['md'].show()
    },
    hide() {
      if (this.ops) {
        this.ops.cancel()
        this.ops = undefined
      }
      this.status = 'idle'
      this.$refs['md'].hide()
    }
  },
  computed: {
    title() {
      return this.status == 'creating'
        ? 'Creating secure link'
        : this.status == 'done'
        ? 'Done'
        : 'Create secure link?'
    }
  },
  watch: {}
}
</script>
