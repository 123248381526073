<template>
  <div>
    <b-tooltip target="tooltip-target-edit-tags" triggers="hover">
      edit tags
    </b-tooltip>
    <b-tooltip target="tooltip-target-exit-edit-tags" triggers="hover">
      cancel
    </b-tooltip>
    <b-tooltip target="tooltip-target-save-edit-tags" triggers="hover">
      save
    </b-tooltip>

    <span class="text-secondary small"
      ><b-icon-tag /> Tags:
      <b-icon-pencil-square
        id="tooltip-target-edit-tags"
        @click.stop
        class="edit-button"
        @click="editing = !editing"
        v-show="editable && !editing"
      />
      <b-icon-file-earmark-arrow-up
        class="edit-button mr-1"
        id="tooltip-target-save-edit-tags"
        @click="save()"
        v-show="!loading && editable && editing"
      />
      <b-icon-x-circle
        class="edit-button"
        id="tooltip-target-exit-edit-tags"
        @click="cancel()"
        v-show="!loading && editable && editing"
      />
      <b-icon
        v-show="loading && editable && editing"
        icon="circle-fill"
        animation="throb"
      ></b-icon>
      <span v-if="errorMessage" class="ml-1 text-danger small">
        {{ errorMessage }}
        <b-icon-exclamation-circle />
      </span>
    </span>

    <div>
      <b-input-group v-show="editable && editing" class="input-group">
        <b-form-input
          v-on:keyup.enter="addTag()"
          v-model="newTag"
          ref="new-tag-input"
          class="no-border"
          size="sm"
          placeholder="enter new tag"
        ></b-form-input>
        <b-input-group-append>
          <b-input-group-text class="tag-add-button">
            <b-icon-plus-circle @click="addTag()" font-scale="0.8" />
          </b-input-group-text>
        </b-input-group-append>
      </b-input-group>
      <div class="text-secondary small" v-if="tags.length == 0 && !editing">
        no tags
      </div>
      <tag
        v-else
        v-for="tag in value"
        :key="tag"
        :ref="`tag${tag}`"
        :selected="true"
        :size="size"
        :selectable="false"
        :value="tag"
        :remove="editable && editing"
      />
    </div>
  </div>
</template>
<script>
import Tag from '../partials/Tag'
import CollectionUtils from '../../utils/CollectionUtils'
import api from '../../api'

export default {
  name: 'tags-container',
  components: {
    Tag
  },
  data() {
    return {
      value: [],
      oldValue: [],
      newTag: '',
      editing: false,
      loading: false,
      errorMessage: undefined
    }
  },
  props: {
    size: {
      type: String,
      default: 's'
    },
    tags: {
      type: Array,
      default: function () {
        return []
      }
    },
    media: {
      type: String,
      default: ''
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.value = this.tags
    let _this = this
    this.$on('delete-tag', function (tag) {
      CollectionUtils.remove(_this.value, tag)
    })
  },
  methods: {
    save() {
      this.loading = true
      let _this = this
      this.errorMessage = undefined
      api
        .updateTags(this.media, this.value)
        .then((media) => {
          CollectionUtils.set(_this.value, media.tags)
          _this.editing = false
          _this.loading = false
        })
        .catch((error) => {
          _this.errorMessage = error
          _this.loading = false
        })
    },
    cancel() {
      this.errorMessage = undefined
      CollectionUtils.set(this.value, this.oldValue)
      this.editing = false
    },
    addTag() {
      if (this.newTag) {
        this.newTag = this.newTag.toLowerCase().trim()
      }
      if (this.newTag.length > 0) {
        if (CollectionUtils.pushUnique(this.value, this.newTag)) {
          this.newTag = ''
        }
      }
    }
  },
  watch: {
    editing(value) {
      if (value) {
        this.oldValue = Array.from(this.value)
        this.$refs['new-tag-input'].focus()
      } else {
        this.oldValue = []
      }
    }
  }
}
</script>
<style scoped>
.input-group {
  margin: 2px 2px 2px 2px;
  width: 10rem;
  border-bottom: 1px #dee2e6 solid;
}
.no-border {
  padding: 0px;
  border: 0px;
}
.tag-add-button {
  background-color: transparent;
  padding: 1px;
  border: 0px;
}
.tag-add-button:hover {
  cursor: pointer;
}
.no-border:focus {
  box-shadow: none;
}
</style>
