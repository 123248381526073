<template>
  <!-- check overlay from the bootstrapvue library -->
  <div
    :style="`--bckroundColor: ${backgroundColor}`"
    class="loader-svg-container"
  >
    <svg
      class="svg"
      preserveAspectRatio="xMidYMin"
      viewBox="0 0 73 65"
      version="1.1"
    >
      <defs>
        <polygon id="path-1" points="0 0 73 0 73 65 0 65"></polygon>
        <polygon
          id="path-3"
          points="0.253906 30.179688 62.957031 0.757812 84.457031 46.578125 21.753906 76"
        ></polygon>
        <polygon
          id="path-5"
          points="0.253906 30.179688 62.957031 0.757812 84.457031 46.578125 21.753906 76"
        ></polygon>
        <polygon
          id="path-7"
          points="0.253906 30.179688 63.488281 0.511719 85.003906 46.367188 21.773438 76.039062"
        ></polygon>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g id="TaggrLogo-no-text">
          <g id="Clipped">
            <mask id="mask-2" fill="white">
              <use xlink:href="#path-1"></use>
            </mask>
            <g id="Path"></g>
            <g mask="url(#mask-2)">
              <g transform="translate(-2.000000, -9.000000)">
                <mask id="mask-4" fill="white">
                  <use xlink:href="#path-3"></use>
                </mask>
                <g id="Path" stroke="none" fill="none"></g>
                <g
                  id="Clipped"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                  mask="url(#mask-4)"
                >
                  <mask id="mask-6" fill="white">
                    <use xlink:href="#path-5"></use>
                  </mask>
                  <g id="Path"></g>
                  <g mask="url(#mask-6)">
                    <mask id="mask-8" fill="white">
                      <use xlink:href="#path-7"></use>
                    </mask>
                    <g id="Path"></g>
                    <path
                      d="M50.246094,10.097656 C48.25,9 45.757812,8.828125 43.511719,9.882812 L6.941406,27.082031 C3.265625,28.808594 1.652344,33.101562 3.347656,36.722656 L18.71875,69.476562 C20.417969,73.097656 24.753906,74.601562 28.429688,72.875 L65.03125,55.742188 C67.273438,54.6875 68.738281,52.660156 69.167969,50.421875 L74.226562,23.449219 L50.246094,10.097656 Z M61.957031,49.191406 L25.371094,66.355469 L10,33.601562 L46.585938,16.433594 L66.0625,27.28125 L61.957031,49.191406 Z"
                      id="Shape"
                      :fill="color"
                      fill-rule="nonzero"
                      mask="url(#mask-8)"
                    >
                      <animate
                        attributeName="opacity"
                        values="0.2;1;0.2"
                        dur="2s"
                        repeatCount="indefinite"
                      />
                    </path>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g
            id="Group"
            transform="translate(25.000000, 16.000000)"
            :fill="textColor"
            fill-rule="nonzero"
          >
            <g id="glyph0-1-link" transform="translate(0.290492, 0.274264)">
              <g id="glyph0-1">
                <path
                  d="M3.5,10.84375 L0.171875,3.546875 L6.921875,0.453125 L10.25,7.75 L14.390625,5.875 L16.734375,11.03125 L12.59375,12.90625 L15.4375,19.125 C16.257812,20.9375 17.1875,21.617188 18.21875,21.15625 C18.46875,21.03125 18.710938,20.859375 18.953125,20.640625 C19.191406,20.414062 19.359375,20.210938 19.453125,20.03125 L19.625,19.78125 L23.78125,24.46875 C22.707031,25.9375 21.242188,27.09375 19.390625,27.9375 C18.117188,28.523438 16.921875,28.804688 15.796875,28.78125 C14.671875,28.761719 13.703125,28.484375 12.890625,27.953125 C12.078125,27.414062 11.378906,26.777344 10.796875,26.046875 C10.210938,25.308594 9.707031,24.46875 9.28125,23.53125 L5.84375,16 L3.015625,17.28125 L0.671875,12.125 L3.5,10.84375 Z"
                  id="Path"
                >
                  <animate
                    attributeName="opacity"
                    values="0.2;1;0.2"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>
<script>
export default {
  name: 'loader-svg',
  props: {
    color: {
      default: '#404040',
      type: String
    },
    textColor: {
      default: '#404040',
      type: String
    },
    backgroundColor: {
      type: String,
      default: '#f8f5f5'
    }
  }
}
</script>
<style scoped>
.loader-svg-container {
  --bckroundColor: #f8f5f5;
  background: var(--bckroundColor);
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  position: relative;
  opacity: 0.8; /* for demo purpose  */
}
.svg {
  width: 50px;
  height: 50px;
  /* Center vertically and horizontally */
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px; /* apply negative top and left margins to truly center the element */
}
</style>
