<template>
  <div ref="main-container" class="recent-uploads">
    <gallery ref="gallery" :media="files" />
    <b-row
      v-bind:class="{ 'position-fixed': selecting }"
      class="header-row align-self-center w-100 bg-light mr-5"
    >
      <b-col>
        <div
          class="header"
          @click="_toggleCollapsed()"
          v-bind:class="{ hidden: collapsed }"
        >
          <span class="h5">{{ _title }}</span>
          <b-icon-arrows-expand
            class="hidden-button my-auto"
            @click="collapsed = !collapsed"
            v-if="collapsable && collapsed"
          />
          <b-icon-arrows-collapse
            class="hidden-button my-auto"
            @click="collapsed = !collapsed"
            v-else-if="collapsable"
          />
        </div>

        <p
          v-if="!selecting"
          v-bind:class="{ 'hidden-subtitle': collapsed }"
          class="text-muted"
        >
          {{ _subtitle }}
        </p>
        <span v-else class="text-muted"
          ><b-checkbox @change="_selectAllChanged" v-model="selectAll"
            >select all</b-checkbox
          ></span
        >
      </b-col>
      <b-col v-if="!selecting">
        <!-- DROP DOWN YEAR -->
        <b-dropdown
          size="sm"
          text="Small"
          variant="link"
          toggle-class="text-decoration-none"
          class="float-right"
        >
          <template #button-content>
            <span class="text-secondary">Year: </span
            >{{ selectedYearOptionLabel }}
          </template>
          <b-dropdown-item
            v-for="(option, index) in yearFilterLabels"
            :key="index"
            @click="onYearOptionSelected(index)"
            href="#"
          >
            <b-icon-check v-if="selectedYearOptionLabel == option" />{{
              option
            }}</b-dropdown-item
          >
        </b-dropdown>
        <!-- DROP DOWN IMAGE - VIDEO -->
        <b-dropdown
          size="sm"
          text="Small"
          variant="link"
          toggle-class="text-decoration-none"
          class="float-right"
        >
          <template #button-content>
            <span class="text-secondary">show </span
            >{{ selectedTypeFilterOptionLabel }}
          </template>
          <b-dropdown-item
            v-for="(option, index) in typeFilterLabels"
            :key="index"
            @click="onTypeOptionSelected(index)"
            href="#"
          >
            <b-icon-check v-if="selectedTypeFilterOptionLabel == option" />{{
              option
            }}</b-dropdown-item
          >
        </b-dropdown>
        <!-- DROP DOWN ORDER -->
        <b-dropdown
          size="sm"
          text="Small"
          variant="link"
          toggle-class="text-decoration-none"
          class="float-right"
        >
          <template #button-content>
            <span class="text-secondary"> </span>{{ selectedSortOrderLabel }}
          </template>
          <b-dropdown-item
            v-for="(option, index) in sortOptionOrdersLabels"
            :key="index"
            @click="onSortOrderSelected(index)"
            href="#"
          >
            <b-icon-check v-if="selectedSortOrderLabel == option" />{{
              option
            }}</b-dropdown-item
          >
        </b-dropdown>
        <!-- DROP DOWN DATE ADDED / TAKEN  -->
        <b-dropdown
          size="sm"
          text="Small"
          variant="link"
          toggle-class="text-decoration-none"
          class="float-right"
        >
          <template #button-content>
            <span class="text-secondary">Sort by </span
            >{{ selectedSortOptionLabel }}
          </template>
          <b-dropdown-item
            v-for="(option, index) in sortOptionLabels"
            :key="index"
            @click="onSortOptionSelected(index)"
            href="#"
          >
            <b-icon-check v-if="selectedSortOptionLabel == option" />{{
              option
            }}</b-dropdown-item
          >
        </b-dropdown>
      </b-col>
      <b-col v-else
        ><b-container
          ><b-button-close
            float-right
            @click="selecting = false" /></b-container
      ></b-col>
    </b-row>

    <div
      class="grid-container"
      ref="grid-container"
      :style="{ top: selecting ? '50px' : '0px', height: contentHeight + 'px' }"
    >
      <ImageTile
        v-for="(image, index) in images"
        :thumbnail="collapsed ? 40 : 0"
        :key="image.src"
        :media="image"
        :index="index"
        :io="io"
        :position="_calculateCurrentIndexPosition(index)"
        :data-ref="`tile-${image.id}`"
        :ref="`tile-${image.id}`"
      />
    </div>

    <div v-if="hasMore" class="row flex-wrap">
      <ImageTilePlaceholder
        :io="files.length > 0 && index == placeholdersCount ? io : undefined"
        :data-ref="`placeholder-${index}`"
        :ref="`placeholder-${index}`"
        :key="index"
        v-for="index in placeholdersCount"
      />
    </div>

    <FloatingActionButtom v-show="selecting">
      <!-- Defines Fab button options -->
      <template slot="option1">
        <div
          v-b-tooltip.hover.left
          title="delete"
          @click="onMenuSelection('delete')"
          class="fab-item"
        >
          <b-icon class="fab-item-svg" icon="trash" />
        </div>
      </template>
      <template slot="option2">
        <div
          v-b-tooltip.hover.left
          title="add tags to selection"
          @click="onMenuSelection('tag')"
          class="fab-item"
        >
          <b-icon class="fab-item-svg" icon="tags" />
        </div>
      </template>
      <template slot="option3">
        <div
          v-b-tooltip.hover.left
          title="share with other users"
          @click="onMenuSelection('share')"
          class="fab-item"
        >
          <b-icon class="fab-item-svg" icon="people" />
        </div>
      </template>
      <template slot="option4">
        <div
          v-b-tooltip.hover.left
          title="create secure link to share with anybody"
          @click="onMenuSelection('link')"
          class="fab-item"
        >
          <b-icon class="fab-item-svg" icon="link" />
        </div>
      </template>
    </FloatingActionButtom>
    <DeleteMediaModal
      :media="Array.from(selectedFiles.values())"
      ref="delete-media-modal"
    />
    <TagMediaModal
      :media="Array.from(selectedFiles.values())"
      ref="tag-media-modal"
    />
    <ShareMediaModal
      :media="Array.from(selectedFiles.values())"
      ref="share-media-modal"
    />
    <LinkMediaModal
      :media="Array.from(selectedFiles.values())"
      ref="link-media-modal"
    />
  </div>
</template>
<script>
import ImageTile from './partials/imageTile.vue'
import ImageTilePlaceholder from './partials/imageTilePlaceholder'
import { MediaRequest } from '../api'
import api from '../api'
import config from '../config'
import FloatingActionButtom from '../components/partials/FloatingActionButton'
import gallery from './gallery/Gallery'
import TagMediaModal from './partials/TagMediaModal'
import DeleteMediaModal from './partials/DeleteMediaModal'
import ShareMediaModal from './partials/ShareMediaModal'
import LinkMediaModal from './partials/LinkMediaModal'
import TilesLayout from '../utils/TilesLayout'

export default {
  name: 'recent-uploads',
  components: {
    ImageTile,
    gallery,
    ImageTilePlaceholder,
    FloatingActionButtom,
    DeleteMediaModal,
    TagMediaModal,
    ShareMediaModal,
    LinkMediaModal
  },
  data() {
    return {
      layout: new TilesLayout(),
      columnsHeight: [],
      hasMore: true,
      collapsed: false,
      files: [],
      years: ['All'],
      totalCount: 0,
      selectedFiles: new Map(),
      selectedFilesCount: 0,
      selectAll: false, // true when the tick box select all is selected
      selecting: false, // true when selecting mode is on
      io: undefined,
      state: 'idle', //[idle, loading, loadingNext]
      currentRequest: undefined,
      requestPromise: undefined,
      itemsPositions: [],
      containerWidth: 0,
      mostRecentMedia: undefined,
      ignoreNextSelectAllUpdate: false //when selectAll is true but we deselect just one image we need to set selectAll == false without deselecting all the other images
    }
  },
  created() {
    this.currentRequest = this.request
    let bi = this.onElementObserved.bind(this)
    this.io = new IntersectionObserver(bi, {
      root: this.$el,
      threshold: 0.25
    })
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
    this.layout.attach(this.$refs['grid-container'])
    this.handleResize()
    let _this = this

    this.$on('media-selected', function (data) {
      let id = data.media.id
      if (data.selected) {
        // _this.ignoreNextSelectAllUpdate = false
        this.selecting = true
        this.selectedFiles.set(id, data)
      } else {
        // _this.ignoreNextSelectAllUpdate = true
        _this._handleItemDeleselected()
        this.selectedFiles.delete(id)
      }
      this.selectedFilesCount = this.selectedFiles.size
    })

    this.$on('image-tile-clicked', function (data) {
      if (this.selecting) {
        let ref = `tile-${data.media.id}`
        const item = Array.isArray(_this.$refs[`${ref}`])
          ? _this.$refs[`${ref}`][0]
          : _this.$refs[`${ref}`]
        item.selected = !item.selected
        if (!item.selected) {
          //  _this.ignoreNextSelectAllUpdate = true
          _this._handleItemDeleselected()
        } else {
          //    _this.ignoreNextSelectAllUpdate = false
        }
      } else {
        console.log('selected media ', data)
        const update = { index: data.index, media: _this.files }
        _this.$store.commit('media/setMediaForGallery', update)
        _this.$refs.gallery.show()
      }
    })
    this.$on('media-deleted', function (data) {
      let ref = `tile-${data.media.id}`
      const item = Array.isArray(_this.$refs[`${ref}`])
        ? _this.$refs[`${ref}`][0]
        : _this.$refs[`${ref}`]
      item.selected = false
      this.selectedFiles.delete(data.media.id)
      this.selectedFilesCount = this.selectedFiles.size
      console.log('deleted media ', data)
      _this.files.splice(item.index, 1)
      _this.handleResize(true)
    })

    this.checkForNewMedia()

    api.getStatsByYear().then((result) => {
      this.years = ['All']
      result['dateTaken'].forEach((y) => {
        this.years.push(y.year)
      })
    })
  },
  beforeDestroy() {
    this.$refs.gallery.onClose()
    window.removeEventListener('resize', this.handleResize)
    this.io.disconnect()
  },
  methods: {
    onMenuSelection(option) {
      if ('delete' == option) {
        this.$refs['delete-media-modal'].show()
      } else if ('tag' == option) {
        this.$refs['tag-media-modal'].show()
      } else if ('share' == option) {
        this.$refs['share-media-modal'].show()
      } else if ('link' == option) {
        this.$refs['link-media-modal'].show()
      }
    },
    _handleItemDeleselected() {
      //handle the logic where select-all is selected and one image is deselected
      if (this.selectAll) {
        this.ignoreNextSelectAllUpdate = true
        this.selectAll = false
      }
    },
    _selectAllChanged(change) {
      console.log('select all changed', change)
    },
    _toggleCollapsed() {
      if (this.collapsable) {
        this.collapsed = !this.collapsed
      }
    },
    _calculateCurrentIndexPosition(index) {
      let current = this.files[index]
      return this.layout.position(index, this.files.length, current.dimension)
    },
    handleResize() {
      this.layout.handleResize()
    },
    onElementObserved(entries) {
      let _this = this
      entries.forEach(({ target, isIntersecting }) => {
        const ref = target.getAttribute('data-ref')
        const item = Array.isArray(_this.$refs[`${ref}`])
          ? _this.$refs[`${ref}`][0]
          : _this.$refs[`${ref}`]
        item['visible'] = isIntersecting
        if (isIntersecting) {
          console.log('visible tile is = ' + ref)
          if (
            ref == `placeholder-${this.placeholdersCount}` &&
            this.files.length > 0
          ) {
            this.currentRequest.currentPage += 1
          }
        }
      })
    },
    onTypeOptionSelected(value) {
      let type = config.getFeatures().media.filter.type.values[value]
      this.$set(this.currentRequest, 'type', type)
    },
    onSortOrderSelected(value) {
      this.$set(this.currentRequest, 'sortOderIndex', value)
      this.$set(this.currentRequest, 'currentPage', 1)
    },
    onSortOptionSelected(value) {
      this.$set(this.currentRequest, 'sortByIndex', value)
      this.$set(this.currentRequest, 'currentPage', 1)
      //this.currentRequest.sortByIndex = value
    },
    onYearOptionSelected(value) {
      value = value == 0 ? undefined : this.yearFilterLabels[value]
      this.$set(this.currentRequest, 'year', value)
      this.$set(this.currentRequest, 'currentPage', 1)
    },
    checkForNewMedia() {
      let _this = this
      let request = new MediaRequest()
      request.pageSize = 1
      api
        .getMedia(request)
        .then((result) => {
          let media = result.items
          if (media && media.length == 1) {
            let m = media[0]
            if (_this.mostRecentMedia == undefined) {
              _this.mostRecentMedia = m
              _this.$set(_this.currentRequest, 'currentPage', 0)
            }
            if (_this.mostRecentMedia.id != m.id) {
              _this.mostRecentMedia = m
              _this.$set(this.currentRequest, 'currentPage', 0)
            }
          }
        })
        .finally(() => {
          setTimeout(() => {
            _this.checkForNewMedia()
          }, 10000)
        })
    }
  },
  computed: {
    contentHeight() {
      return this.layout.contentHeight
    },
    _title() {
      if (this.currentSearch && this.currentSearch.length > 0) {
        return this.currentSearch
      } else if (this.selecting == true) {
        return this.selectedFilesCount + ' selected'
      }
      return this.title
    },
    _subtitle() {
      if (this.currentSearch && this.currentSearch.length > 0) {
        return this.totalCount > 0
          ? 'Showing ' +
              this.totalCount +
              ' search results ordered by ' +
              this.selectedSortOptionLabel
          : 'No results'
      }
      return this.subtitle(this.totalCount)
    },
    placeholdersCount() {
      return this.files.length == 0 ? 10 : 1
    },
    currentSearch() {
      return this.$store.state.media.searchTerm
    },
    typeFilterLabels() {
      return config.getFeatures().media.filter.type.labels
    },
    selectedTypeFilterOptionLabel() {
      let index = 0
      let selected = this.currentRequest.type
      if (selected == 'image') index = 1
      if (selected == 'video') index = 2
      return config.getFeatures().media.filter.type.labels[index]
    },
    selectedTypeFilterOption() {
      let selected = this.currentRequest.type
      return selected
    },
    sortOptionLabels() {
      return config.getFeatures().media.sort.byLabel
    },
    sortOptionOrdersLabels() {
      return config.getFeatures().media.sort.sortOderLabels
    },
    selectedSortOrderLabel() {
      let selected = this.currentRequest.sortOderIndex
      return config.getFeatures().media.sort.sortOderLabels[selected]
    },
    selectedSortOptionLabel() {
      let selected = this.currentRequest.sortByIndex
      return config.getFeatures().media.sort.byLabel[selected]
    },
    yearFilterLabels() {
      return this.years
    },
    selectedYearOptionLabel() {
      if (this.currentRequest.year == undefined) return 'All'
      return this.currentRequest.year
    },
    images() {
      return this.collapsed && this.files.length > 10
        ? this.files.slice(0, 10)
        : this.files
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: Function,
      default: function (count) {
        return 'Showing ' + count + ' items'
      }
    },
    collapsable: {
      type: Boolean,
      default: false
    },
    request: {
      type: MediaRequest,
      default: function () {
        let req = new MediaRequest()
        req.requestTotal(true)
        return req
      }
    }
  },
  watch: {
    selectedFilesCount(current) {
      if (current == 0) {
        this.selecting = false
      }
    },
    selectAll(current) {
      if (this.ignoreNextSelectAllUpdate) {
        this.ignoreNextSelectAllUpdate = false
        return
      }
      let _this = this
      _this.$nextTick(() => {
        _this.files.forEach((value) => {
          let ref = `tile-${value.id}`
          const item = Array.isArray(_this.$refs[`${ref}`])
            ? _this.$refs[`${ref}`][0]
            : _this.$refs[`${ref}`]
          item.selected = current
        })
      })
    },
    currentSearch(value) {
      this.$set(this.currentRequest, 'searchTerm', value)
      this.$set(this.currentRequest, 'currentPage', 1)
    },
    currentRequest: {
      handler: function () {
        console.log(
          'current request is = ' + JSON.stringify(this.currentRequest)
        )
        if (this.currentRequest.currentPage == 1 && this.files.length > 0) {
          this.selecting = false
          this.files = []
          this.selectedFilesCount = 0
          this.selectedFiles = new Map()
          this.layout.reset()
        }

        this.requestPromise = api
          .getMedia(this.currentRequest)
          .then((response) => {
            this.totalCount = response.meta.total
            let media = response.items
            this.hasMore = media.length == this.currentRequest.pageSize
            if (this.currentRequest.currentPage == 1) {
              this.files = media
            } else {
              this.files = this.files.concat(media)
            }
            // this.positionElements()
          })
          .finally(() => {
            this.requestPromise = undefined
          })
      },
      deep: true
    },
    selecting(current) {
      if (!current) {
        this.selectAll = false
        let _this = this
        this.selectedFiles.forEach((value) => {
          let ref = `tile-${value.media.id}`
          const item = Array.isArray(_this.$refs[`${ref}`])
            ? _this.$refs[`${ref}`][0]
            : _this.$refs[`${ref}`]
          item.selected = false
        })
      }
    }
  }
}
</script>
<style scoped>
.grid-container {
  display: block;
  position: relative;
  background-color: transparent;
}

.header-row {
  background: whitesmoke;
  width: 100%;
  z-index: 1000;
}

.selected-button-options {
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  border-radius: 50%;
  background: gold;
  border: 1px gold solid;
}

.pmd-btn-raised {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.header {
  cursor: pointer;
}
.hidden {
  color: gray;
  font-size: 1rem;
}
.hidden-subtitle {
  font-size: 0.7rem;
}
.hidden-button {
  color: gray;
  margin: 4px;
  cursor: pointer;
  font-size: 0.8rem;
}
</style>
