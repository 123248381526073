<template>
  <b-modal
    scrollable
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
    centered
    :title="title"
    size="md"
    ref="md"
  >
    <b-container fluid>
      <b-progress
        v-if="status == 'sharing' || status == 'done'"
        :value="progress"
        :max="media.length"
        show-progress
        animated
      ></b-progress>
      <div class="modal-container" v-else>
        <friend-item
          v-for="friend in friends"
          :key="friend.id"
          :friend="friend"
          :observer="observer()"
        />
      </div>
    </b-container>
    <template #modal-footer>
      <div class="w-100 justify-content-end d-flex">
        <b-button
          class="mr-1"
          :disabled="status != 'idle'"
          variant="secondary"
          size="sm"
          @click="hide()"
        >
          Cancel
        </b-button>
        <b-button
          :disabled="status != 'idle' || selectedFriends.length == 0"
          variant="success"
          size="sm"
          @click="start()"
        >
          Share
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import FriendItem from './FriendItem'
import CollectionUtils from '../../utils/CollectionUtils'
import MediaBatchOperation from '../../utils/MediaBatchOperation'
import api from '../../api'
import Timer from '../../utils/Timer'

export default {
  name: 'share-media-modal',
  components: {
    FriendItem
  },
  data() {
    return {
      ops: undefined,
      progress: 0,
      status: 'idle', //sharing, done
      friends: [],
      selectedFriends: [],
      closeTimer: undefined
    }
  },
  props: {
    media: Array
  },
  mounted() {
    const _this = this
    this.closeTimer = new Timer(2000, function () {
      _this.hide()
    })

    this.$on('friend-selected', function (data) {
      if (data.selected) {
        _this.selectedFriends.push(data.id)
      } else {
        CollectionUtils.remove(_this.selectedFriends, data.id)
      }
    })
  },
  methods: {
    observer() {
      return this
    },
    start() {
      this.status = 'sharing'
      const _this = this
      this.ops = new MediaBatchOperation(this.media, function (m, p) {
        api
          .shareMedia(m.media.id, _this.selectedFriends)
          .then(() => {
            p.resolve('shared')
            _this.$parent.$emit('media-shared', {
              media: m.media,
              index: m.index
            })
          })
          .catch((error) => {
            p.reject('ERROR_REQUEST_NETWORK_ERROR', error)
          })

        ///
      })

      this.ops.start(function (count) {
        _this.progress = count
      })
    },
    show() {
      this.status = 'idle'
      this.progress = 0
      this.$log.debug('Show share media dialog for :', this.media)
      api.getFriends().then((friends) => {
        this.friends = friends
      })
      this.$refs['md'].show()
    },
    hide() {
      if (this.ops) {
        this.ops.cancel()
        this.ops = undefined
      }
      this.status = 'idle'
      this.$refs['md'].hide()
    }
  },
  computed: {
    title() {
      return this.status == 'done'
        ? 'Done'
        : this.status == 'sharing'
        ? 'Sharing ' + this.media.length + ' files'
        : 'Select users to share'
    }
  },
  watch: {
    progress(current) {
      if (current == this.media.length) {
        this.status = 'done'
        this.closeTimer.start()
      }
    }
  }
}
</script>
<style scoped>
.modal-container {
  min-height: 10rem;
}
.new-tag-control-input,
.new-tag-control-input::before,
.new-tag-control-input:focus {
  border: 1 !important;
  box-shadow: none !important;
  outline: none !important;
}
</style>
