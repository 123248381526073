<template>
  <b-modal
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
    centered
    :title="title"
    size="md"
    ref="md"
  >
    <b-container fluid>
      <b-progress
        v-if="status == 'deleting' || status == 'done'"
        :value="progress"
        :max="localCopy.length"
        show-progress
        animated
      ></b-progress>
      <p v-else>
        are you sure you want to proceed? Deleting files can't be undone
      </p>
    </b-container>
    <template #modal-footer>
      <div class="w-100 justify-content-end d-flex">
        <b-button
          class="mr-1"
          :disabled="status != 'idle'"
          variant="secondary"
          size="sm"
          @click="hide()"
        >
          Cancel
        </b-button>
        <b-button
          :disabled="status != 'idle'"
          variant="danger"
          size="sm"
          @click="start()"
        >
          Delete
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import MediaBatchOperation from '../../utils/MediaBatchOperation'
import api from '../../api'
import Timer from '../../utils/Timer'

export default {
  name: 'delete-media-modal',
  components: {},
  data() {
    return {
      ops: undefined,
      progress: 0,
      status: 'idle', //deleting, done
      deleting: false,
      localCopy: function () {
        return []
      },
      closeTimer: undefined
    }
  },
  props: {
    media: Array
  },
  mounted() {
    const _this = this
    this.closeTimer = new Timer(2000, function () {
      _this.hide()
    })
  },
  methods: {
    start() {
      this.status = 'deleting'
      const _this = this
      this.ops = new MediaBatchOperation(this.localCopy, function (m, p) {
        api
          .deleteMedia(m.media.id)
          .then(() => {
            p.resolve('deleted')
            _this.$parent.$emit('media-deleted', {
              media: m.media,
              index: m.index
            })
          })
          .catch((error) => {
            p.reject('ERROR_REQUEST_NETWORK_ERROR', error)
          })

        ///
      })

      this.ops.start(function (count) {
        _this.progress = count
      })
    },
    show() {
      this.status = 'idle'
      this.localCopy = Array.from(this.media)
      this.progress = 0
      this.$log.debug('Show delete media dialog for :', this.media)
      this.$refs['md'].show()
    },
    hide() {
      if (this.ops) {
        this.ops.cancel()
        this.ops = undefined
      }
      this.status = 'idle'
      this.$refs['md'].hide()
    }
  },
  computed: {
    title() {
      return this.status == 'deleting'
        ? 'Deleting ' + this.localCopy.length + ' files'
        : this.status == 'done'
        ? 'Done'
        : 'Delete ' + this.localCopy.length + ' files?'
    }
  },
  watch: {
    progress(current) {
      if (current == this.localCopy.length) {
        this.status = 'done'
        this.closeTimer.start()
      }
    }
  }
}
</script>
