<template>
  <div
    class="image-tile-placeholder"
    :style="{ height: computedHeight + 'px', width: computedWidth + 'px' }"
  >
    <loader-svg />
    <div class="placeholder-tags-container">
      <div class="placeholder-tag" v-for="tag in tags" :key="tag">
        {{ tag }}
      </div>
    </div>
  </div>
</template>
<script>
import LoaderSvg from './LoaderSvg.vue'

export default {
  name: 'image-tile-placeholder',
  components: {
    LoaderSvg
  },
  data() {
    return {
      visible: false,
      observe: 0
    }
  },
  props: {
    rem: {
      type: Number,
      default: 18
    },
    io: {
      type: IntersectionObserver,
      default: undefined
    }
  },
  computed: {
    tags() {
      return ['    ', '    ', '     ', '      ']
    },
    aspectRatio() {
      return 1 + Math.random()
    },
    computedHeight() {
      var rempx = this.convertRemToPixels(this.rem)
      return rempx
    },
    computedWidth() {
      return Math.ceil(this.computedHeight * this.aspectRatio)
    }
  },
  mounted() {
    if (this.io) {
      this.observe++
      this.io.observe(this.$el)
    }
  },
  methods: {
    convertRemToPixels(rem) {
      return (
        rem * parseFloat(getComputedStyle(document.documentElement).fontSize)
      )
    }
  },
  watch: {
    io(current) {
      if (current) {
        this.observe++
        current.observe(this.$el)
      }
    }
  }
}
</script>
<style scoped>
.image-tile-placeholder {
  position: relative;
  display: inline-block;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  transition: 0.4s;
  margin: 5px;
  background: rgba(0, 0, 0, 0);
  z-index: 0;
}

.placeholder-tags-container {
  max-width: 50%;
  height: 1.6rem;
  display: inline-block;
  flex-wrap: wrap;
  overflow: hidden !important;
  color: white;
  position: relative;
  bottom: 25px;
  left: 2px;
}

.placeholder-tag {
  font-family: BlinkMacSystemFont, San Francisco, Helvetica Neue, Helvetica;

  min-width: 3rem;
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 2px;
  padding-bottom: 2px;
  display: inline-block;
  --color: #dcdcdc;
  background: white;
  margin: 0.3rem;
  border-color: var(--color);
  border-style: solid;
  border-width: 2px;
  border-radius: 15px;
  text-align: center;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  user-select: none;
  color: white;
  background: var(--color);
  border-color: var(--color);
  display: inline-block;
  border-style: solid;
  text-align: center;
}
</style>
