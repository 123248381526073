import EXIF from 'exif-js'
import TFile from '../utils/TFile'
import ResponsePromise from '../api/ResponsePromise'

var URL = window.webkitURL || window.URL || self.URL

const hasArrayBufferViewSupport =
  typeof Uint8Array !== 'undefined' &&
  (function checkArrayBufferView() {
    try {
      return new Blob([new Uint8Array(100)]).size === 100
    } catch (error) {
      return false
    }
  })()

class ImageTools {
  constructor() {}

  resize = (file, maxDimensions, output = 'URL', useExif = false) =>
    new ResponsePromise((resolve, reject, progress, canceled) => {
      if (file instanceof TFile) {
        file = file.file
      }

      if (canceled.get() == true) {
        reject('canceled')
      }

      if (!file.type.match(/image.*/)) return reject(file) // early exit - not supported

      if (file.type.match(/image\/gif/)) return reject(file) // early exit - could be an animated gif

      const image = document.createElement('img')

      image.onload = () => {
        if (canceled.get() == true) {
          reject('canceled')
        }

        let width = image.width
        let height = image.height

        if (width >= height && width > maxDimensions.width) {
          height *= maxDimensions.width / width
          width = maxDimensions.width
        } else if (height > maxDimensions.height) {
          width *= maxDimensions.height / height
          height = maxDimensions.height
        } else return resolve(file) // early exit; no need to resize

        if (useExif == true) {
          if (canceled.get() == true) {
            reject('canceled')
          }
          EXIF.getData(image, () => {
            if (canceled.get() == true) {
              reject('canceled')
            }

            const orientation = EXIF.getTag(image, 'Orientation')
            const imageCanvas = this.drawImageToCanvas(
              image,
              orientation,
              0,
              0,
              width,
              height,
              'contain'
            )
            imageCanvas.toBlob(
              (blob) => resolve(this.out(blob, output)),
              file.type
            )
          })
        } else {
          const imageCanvas = this.drawImageToCanvas(
            image,
            0,
            0,
            0,
            width,
            height,
            'contain'
          )
          imageCanvas.toBlob(
            (blob) => resolve(this.out(blob, output)),
            file.type
          )
        }
      }

      if (canceled.get() == true) {
        reject('canceled')
      }
      this.loadImage(image, file)

      return true
    })

  out(blob, type) {
    if (type == 'URL') {
      return URL.createObjectURL(blob)
    }
    return blob
  }

  capture(file, dimensions) {
    if (file instanceof TFile) {
      file = file.file
    }

    return new ResponsePromise((resolve) => {
      let video = document.createElement('video')
      let canvas = document.createElement('canvas')

      video.addEventListener('loadedmetadata', function () {
        video.currentTime = 4
        // Set canvas dimensions same as video dimensions
        canvas.width = dimensions.width //video.videoWidth
        canvas.height = dimensions.height // video.videoHeight

        let ctx = canvas.getContext('2d')

        ctx.drawImage(
          video,
          video.videoWidth / 2 - dimensions.width / 2,
          video.videoHeight / 2 - dimensions.height / 2,
          canvas.width,
          canvas.height
        )
        let dataURI = canvas.toDataURL('image/jpeg')
        resolve(dataURI)
      })

      video.src = URL.createObjectURL(file)
    })
  }

  crop = (file, dimensions, output = 'URL', useExif = false) => {
    if (file instanceof TFile) {
      file = file.file
    }
    if (file.type.match(/video.*/)) {
      return this.capture(file, dimensions)
    } else
      return new ResponsePromise((resolve, reject, progress, canceled) => {
        //if video file
        if (canceled.get() == true) {
          reject('canceled')
        } else if (!file.type.match(/image.*/)) {
          reject(file) // early exit - not supported
          return
        } else if (file.type.match(/image\/gif/)) {
          reject(file) // early exit - could be an animated gif
          return
        }

        const image = document.createElement('img')

        image.onload = () => {
          if (canceled.get() == true) {
            reject('canceled')
          }
          //  if (dimensions.width > image.width && dimensions.height > image.height) return resolve(file); // early exit - no need to resize

          const width = Math.min(dimensions.width, image.width)
          const height = Math.min(dimensions.height, image.height)

          if (
            image.width > dimensions.width * 2 ||
            image.height > dimensions.height * 2
          ) {
            return this.resize(
              file,
              { width: dimensions.width * 2, height: dimensions.height * 2 },
              'blob'
            ).then((zoomedOutImage) => {
              this.crop(zoomedOutImage, { width, height }).then(resolve)
            })
          }

          if (useExif == false) {
            if (canceled.get() == true) {
              reject('canceled')
            }
            const imageCanvas = this.drawImageToCanvas(
              image,
              0,
              0,
              0,
              width,
              height,
              'crop'
            )
            imageCanvas.toBlob(
              (blob) => resolve(this.out(blob, output)),
              file.type
            )
          } else {
            EXIF.getData(image, () => {
              const orientation = EXIF.getTag(image, 'Orientation')
              const imageCanvas = this.drawImageToCanvas(
                image,
                orientation,
                0,
                0,
                width,
                height,
                'crop'
              )
              imageCanvas.toBlob(
                (blob) => resolve(this.out(blob, output)),
                file.type
              )
            })
          }
        }

        this.loadImage(image, file)

        return true
      })
  }

  drawImageToCanvas = (
    img,
    orientation = 1,
    x = 0,
    y = 0,
    width = img.width,
    height = img.height,
    method = 'contain'
  ) => {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    canvas.width = width
    canvas.height = height

    ctx.save()
    var or = Number(orientation)
    or = 1
    switch (or) {
      // explained here: https://i.stack.imgur.com/6cJTP.gif
      case 1:
        break

      case 2:
        ctx.translate(width, 0)
        ctx.scale(-1, 1)
        break

      case 3:
        ctx.translate(width, height)
        ctx.rotate((180 / 180) * Math.PI) // 180/180 is 1? No shit, but how else will you know its need 180 rotation?
        break

      case 4:
        ctx.translate(0, height)
        ctx.scale(1, -1)
        break

      case 5:
        canvas.width = height
        canvas.height = width
        ctx.rotate((90 / 180) * Math.PI)
        ctx.scale(1, -1)
        break

      case 6:
        canvas.width = height
        canvas.height = width
        ctx.rotate((90 / 180) * Math.PI)
        ctx.translate(0, -height)
        break

      case 7:
        canvas.width = height
        canvas.height = width
        ctx.rotate((270 / 180) * Math.PI)
        ctx.translate(-width, height)
        ctx.scale(1, -1)
        break

      case 8:
        canvas.width = height
        canvas.height = width
        ctx.translate(0, width)
        ctx.rotate((270 / 180) * Math.PI)
        break

      default:
        break
    }

    if (method === 'crop')
      ctx.drawImage(
        img,
        img.width / 2 - width / 2,
        img.height / 2 - height / 2,
        width,
        height,
        0,
        0,
        width,
        height
      )
    else ctx.drawImage(img, x, y, width, height)
    ctx.restore()

    return canvas
  }

  toBlob = (canvas, type) => {
    const dataURI = canvas.toDataURL(type)
    const dataURIParts = dataURI.split(',')
    let byteString
    if (dataURIParts[0].indexOf('base64') >= 0) {
      byteString = atob(dataURIParts[1])
    } else {
      byteString = decodeURIComponent(dataURIParts[1])
    }
    const arrayBuffer = new ArrayBuffer(byteString.length)
    const intArray = new Uint8Array(arrayBuffer)

    for (let i = 0; i < byteString.length; i += 1) {
      intArray[i] = byteString.charCodeAt(i)
    }

    const mimeString = dataURIParts[0].split(':')[1].split(';')[0]
    let blob = new Blob([hasArrayBufferViewSupport ? intArray : arrayBuffer], {
      type: mimeString
    })
    return URL.createObjectURL(blob)
  }

  loadImage = (image, file) => {
    if (file instanceof TFile) {
      file = file.file
    }

    if (typeof URL === 'undefined') {
      const reader = new FileReader()
      reader.onload = (event) => {
        image.src = event.target.result
      }
      reader.readAsDataURL(file)
    } else {
      image.src = URL.createObjectURL(file)
    }
  }

  revoke(url) {
    URL.revokeObjectURL(url)
  }
}

export default new ImageTools()
