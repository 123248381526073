<template>
  <b-modal centered hide-footer title="Info" size="lg" ref="md">
    <b-container fluid>
      <b-row>
        <b-col>
          <media-property
            name="Taken On"
            id="date-taken"
            :value="dateTaken"
            icon="calendar-date"
            editable
          />
        </b-col>
        <b-col>
          <media-property
            name="Uploaded On"
            id="date-uploaded"
            :value="dateUploaded"
            icon="upload"
          />
        </b-col>
        <div class="w-100"></div>
        <b-col>
          <media-property
            name="Dimension"
            id="media-dimension"
            :value="dimension"
            icon="image"
          />
        </b-col>
        <b-col>
          <media-property
            name="Camera Model"
            id="camera-model"
            :value="cameraModel"
            icon="camera"
          />
        </b-col>
        <div class="w-100"></div>
        <b-col>
          <media-property
            name="Size"
            id="media-size"
            :value="size"
            icon="file-ruled"
          />
        </b-col>
      </b-row>
      <tag-container
        :editable="true"
        class="w-100"
        style="border-top: 1px solid #dee2e6"
        size="s"
        :media="media.id"
        :tags="media.tags"
      />
      <b-row> </b-row>
    </b-container>
  </b-modal>
</template>
<script>
import TagContainer from '../partials/TagsContainer'
import MediaProperty from './MediaProperty'
import { getReadableFileSizeString } from '../../utils'
import config from '../../config'
import dateFormat from 'dateformat'

export default {
  name: 'image-info-modal',
  components: {
    MediaProperty,
    TagContainer
  },
  props: {
    media: Object
  },
  methods: {
    show() {
      this.$log.debug('Show dialog for media:', this.media.id)
      this.$refs['md'].show()
    },
    hide() {
      this.$refs['md'].hide()
    }
  },
  computed: {
    cameraModel() {
      return this.media.cameraModel ? this.media.cameraModel : ' - '
    },
    dimension() {
      return `${this.media.dimension.width}x${this.media.dimension.height}px`
    },
    size() {
      return getReadableFileSizeString(this.media.size)
    },
    dateTaken() {
      if (this.media.dateTaken) {
        return dateFormat(
          this.media.dateTaken,
          config.getFeatures().media.dateFormat
        )
      } else {
        return '-'
      }
    },
    dateUploaded() {
      return dateFormat(
        this.media.createdDate,
        config.getFeatures().media.dateFormat
      )
    }
  }
}
</script>
