<template>
  <div>
    <div class="d-flex align-items-center justify-content-between mb-3">
      <h5 class="mb-0">Media Shared via Secure Link</h5>
      <a href="#" class="btn btn-link text-muted">Show all</a>
    </div>
    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col">Code</th>
          <th scope="col">User Agent</th>
          <th scope="col">Created On</th>
          <th scope="col">Latest Activity</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="s in shares" :key="s.id">
          <td>{{ s.shortCode }}</td>
          <td>{{ s.latestEvent | userAgentFormat }}</td>
          <td>{{ s.createdDate | dateFormat }}</td>
          <td>{{ s.latestEvent | eventFormat }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import api from '../api'
import UAParser from 'ua-parser-js'
import dateFormat from 'dateformat'
export default {
  name: 'shares-table',
  data() {
    return {
      shares: []
    }
  },
  mounted() {
    api.getShares().then((shares) => {
      this.shares = shares
    })
  },
  filters: {
    dateFormat(date) {
      return dateFormat(date)
    },
    userAgentFormat(event) {
      if (event == undefined || event.userAgent == undefined) {
        return ''
      }
      let ua = UAParser(event.userAgent)
      let result = ''
      if (ua.device) {
        if (ua.device.model) {
          result += ua.device.model
        }
      }
      if (ua.browser) {
        if (ua.browser.name) {
          result += ua.browser.name
        }
      }
      return result
    },
    eventFormat(event) {
      return event != undefined
        ? event.type +
            ' ' +
            event.context +
            ' on ' +
            dateFormat(event.createdDate) +
            ' via ip ' +
            event.remoteIP
        : ' - '
    }
  }
}
</script>
