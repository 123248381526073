<template>
  <div
    v-if="state != 'deleted'"
    :class="selected ? 'image-card-container-selected' : 'image-card-container'"
    :style="{
      height: position['h'] + 'px',
      width: position['w'] + 'px',
      left: position['left'] + 'px',
      top: position['top'] + 'px'
    }"
  >
    <img
      v-show="src != undefined"
      @click="onClick('main-image')"
      class="image-card-image border"
      :style="{ height: position['h'] + 'px', width: position['w'] + 'px' }"
      :src="src"
      alt="Card image"
    />

    <loader-svg v-if="showLoader" />
    <div v-if="thumbnail == 0" class="image-card-tags">
      <tag
        v-for="tag in tags"
        :key="tag"
        :ref="`tag${tag}`"
        :selected="true"
        size="s"
        :selectable="false"
        :color="colorProvider.get(tag)"
        :value="tag"
      />
    </div>
    <div class="image-card-duration-label" v-show="duration != undefined">
      {{ duration }}
    </div>
    <b-icon
      @click="selected = !selected"
      @click.stop
      :icon="selected ? 'check-circle-fill' : 'check-circle'"
      :class="
        selected ? 'image-card-selected-button' : 'image-card-select-button'
      "
    />

    <div v-show="thumbnail == 0" class="image-card-button">
      <b-dropdown
        id="dropdown-dropup"
        toggle-class="text-decoration-none bg-transparent border-0"
        dropup
        text="thumbnail-options"
        lazy
        no-caret
      >
        <template v-slot:button-content>
          <b-icon-three-dots-vertical />
        </template>
        <b-dropdown-item @click="onClick('info')">Info</b-dropdown-item>
        <!-- <b-dropdown-item @click="onClick('add_to_album')"
          >Add to Album</b-dropdown-item
        > -->
        <!-- <b-dropdown-item @click="onClick('download')">Download</b-dropdown-item> -->
        <b-dropdown-item @click="onClick('delete')">Delete</b-dropdown-item>
      </b-dropdown>
    </div>
    <ImageInfoModal :media="media" :ref="uniqueReference" />
  </div>
</template>
<script>
function isInViewport(el, percentVisible) {
  let rect = el.getBoundingClientRect(),
    windowHeight = window.innerHeight || document.documentElement.clientHeight

  return !(
    Math.floor(
      100 - ((rect.top >= 0 ? 0 : rect.top) / +-(rect.height / 1)) * 100
    ) < percentVisible ||
    Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) <
      percentVisible
  )
}

import LoaderSvg from './LoaderSvg.vue'
import Tag from './Tag'
import ColorUtils from '../../utils/ColorUtils'
import ImageInfoModal from '../partials/ImageInfoModal'
import api from '../../api'

export default {
  name: 'image-tile',
  components: {
    LoaderSvg,
    Tag,
    ImageInfoModal
  },
  data() {
    return {
      visible: false,
      colorProvider: ColorUtils.randomColor(),
      src: undefined,
      request: undefined,
      selected: false,
      state: 'idle' // loading , loaded , error, deleting, deleted ;
    }
  },
  props: {
    rem: {
      type: Number,
      default: 18
    },
    width: {
      type: Number,
      default: 400
    },
    thumbnail: {
      type: Number,
      default: 0
    },
    position: {
      type: Object,
      default: function () {
        return { left: 0, top: 0, h: 0, w: 0 }
      }
    },
    index: Number,
    media: Object,
    io: {
      type: IntersectionObserver,
      default: undefined
    }
  },
  mounted() {
    this.visible = isInViewport(this.$el, 5)
    this.io.observe(this.$el)
    this.load()
  },
  unmounted() {},
  computed: {
    showLoader() {
      return this.state == 'loading' || this.state == 'deleting'
    },
    uniqueReference() {
      return 'current-media-' + this.media['_id']
    },
    duration() {
      if (this.media.type.indexOf('video') == -1) return undefined

      if (this.media.duration != undefined) {
        return new Date(this.media.duration * 1000).toISOString().substr(11, 8)
      }
      return '00:00:00'
    },
    tags() {
      if (!Array.isArray(this.media.tags)) return []
      if (this.media.tags.length > 3) {
        let tg = this.media.tags.slice(0, 3)
        //tg.push('...')
        return tg
      }
      return this.media.tags
    },
    aspectRatio() {
      return this.thumbnail > 0
        ? 1
        : this.media.dimension.width / this.media.dimension.height
    }
  },
  methods: {
    load() {
      if (this.visible && (this.state == 'idle' || this.state == 'error')) {
        this.state = 'loading'
        let location = this.media.location
        let w = this.position['w']
        let h = this.position['h']
        let type = undefined
        const _this = this
        let cb = (src, error) => {
          _this.request = undefined
          if (error) {
            _this.state = 'idle'
          } else {
            _this.state = 'loaded'
            _this.src = src
          }
        }

        this.request = api.getImage({ location, w, h, type, cb })
      }
    },
    onClick(event) {
      if ('media-selected' == event) {
        this.$parent.$emit('media-selected', {
          selected: this.selected,
          media: this.media,
          index: this.index
        })
      } else if ('main-image' == event) {
        this.$parent.$emit('image-tile-clicked', {
          media: this.media,
          index: this.index
        })
      } else if ('info' == event) {
        this.$refs[this.uniqueReference].show()
      } else if ('delete' == event) {
        if (confirm('Are you sure you want to delete this media?')) {
          this.state = 'deleting'
          api
            .deleteMedia(this.media.id)
            .then(() => {
              this.state = 'deleted'
              this.$parent.$emit('media-deleted', {
                media: this.media,
                index: this.index
              })
            })
            .catch((error) => {
              this.state = 'error'
              console.log('error deleting media', error)
            })
        }
      }
    }
  },
  watch: {
    selected() {
      this.onClick('media-selected')
    },
    visible() {
      if (this.visible) {
        this.load()
      } else if (this.request) {
        this.$log.debug('cancelling request for ' + this.media)
        this.request.cancel('Item not visible ')
        this.request = undefined
      }
    },
    media: {
      handler: function () {
        this.request = undefined
        this.src = undefined
        this.state = 'idle'
        this.load()
      },
      deep: true
    }
  }
}
</script>
