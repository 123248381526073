import config from '../config'

class TFileUtils {
  /**
   *
   * @param {TFile} file
   * @returns true if the file mime type is listed under the supported mime types inside the configuration
   */
  static isFileTypeSupported(file) {
    const types = config.getAllowedMediaFormat()
    return types.indexOf(file.type()) > -1
  }

  /**
   *
   * @param {TFile} file
   * @returns true if the file is an image or video or audio and this type is listed in the settings
   * in the allowed media types
   */
  static getSupportedFileClass(file) {
    const types = config.getAllowedMediaType()
    return types.indexOf(file.class()) > -1
  }
}

export default TFileUtils
