<template>
  <b-navbar class="navbar-custom" sticky toggleable="lg" type="light">
    <router-link to="dashboard">
      <b-navbar-brand>
        <img class="logo" src="img/brand/logo-no-text.png" alt="Taggr" />
      </b-navbar-brand>
    </router-link>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <b-input-group class="w-75">
        <template v-if="showSearchFilters" #append>
          <div
            class="form-control button-search-options"
            @click="$refs.modalfilters.show()"
          >
            <b-icon-sliders />
          </div>
        </template>
        <b-form-input
          type="search"
          autocomplete="off"
          list="tags-list-id"
          v-model="searchText"
          v-on:keyup.enter="onSearchEnter"
          placeholder="Search your photos and videos"
        />
        <datalist v-if="searchText && searchText.length > 0" id="tags-list-id">
          <option v-for="tag in $store.state.media.tags" :key="tag">
            {{ tag }}
          </option>
        </datalist>
      </b-input-group>

      <b-button
        v-on:click="toggleUploadVisibility"
        class="ml-auto navbar-toggle hidden-xs"
        variant="success"
        type="submit"
      >
        <b-icon icon="cloud-upload" aria-hidden="true"></b-icon>
        Upload
      </b-button>

      <!-- Right aligned nav items -->

      <b-navbar-nav>
        <b-nav-item-dropdown right>
          <template v-slot:button-content>
            <b-avatar
              src="https://placekitten.com/300/300"
              variant="primary"
            ></b-avatar>
          </template>

          <b-dropdown-item to="profile">Profile</b-dropdown-item>
          <b-dropdown-item @click="logout()" href="#">Sign Out</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
    <light-box size="m" showCloseButton ref="modalfilters"> </light-box>
    <LightBox
      ref="modalupload"
      showCloseButton
      :onCloseCallback="onLightBoxClose"
      centered
      size="xl"
      scrollable
    >
      <UploadTabs ref="uploadTabs" />
    </LightBox>
  </b-navbar>
</template>
<script>
// @ is an alias to /src
import LightBox from './LightBox.vue'
import UploadTabs from './UploadTabs.vue'
import config from './../config'

export default {
  name: 'PNav',
  components: {
    LightBox,
    UploadTabs
  },
  data: function () {
    return {
      mediaInGallery: [],
      showSearchFilters: config.getFeatures().search.filters,
      searchText: undefined,
      modals: {
        upload: false
      }
    }
  },
  mounted() {
    this.$store.dispatch('media/getTags')
    this.$store.dispatch('auth/userInfo2')
  },
  methods: {
    onLightBoxClose() {
      return this.$refs.uploadTabs.onLightBoxClose()
    },
    toggleUploadVisibility() {
      this.$refs.modalupload.show()
    },
    onSearchEnter() {
      this.$store.dispatch('media/searchMedia', this.searchText)
    },
    logout() {
      var to = this.$router.resolve({ name: 'home' })
      this.$store.dispatch('auth/logout', to)
    }
  },
  watch: {
    searchText(current) {
      if (!current || current.length == 0) {
        this.$store.dispatch('media/searchMedia', this.searchText)
      }
    }
  }
}
</script>
<style scoped>
.button-search-options {
  border-top-left-radius: 0rem;
  border-bottom-left-radius: 0rem;
  color: lightgray;
  cursor: pointer;
}
.button-search-options:hover {
  background-color: lightgray;
  color: whitesmoke;
}
</style>
