<template>
  <!-- <div @click.stop="closeLightBox"> -->
  <div>
    <transition mode="out-in" name="lightbox-container-transition">
      <div v-show="lightBoxOn" ref="container" class="lightbox-container">
        <div :class="size" class="lightbox-content">
          <slot></slot>
        </div>

        <b-icon
          v-if="showCloseButton"
          @click.stop="closeLightBox"
          variant="light"
          class="lightbox-close lightbox-hideable"
          icon="x-circle-fill"
          aria-label="Help"
        ></b-icon>
      </div>
      <!-- .lightbox-container -->
    </transition>
  </div>
</template>

<script>
export default {
  components: {},

  props: {
    size: {
      type: String,
      default: 'xl'
    },
    disableScroll: {
      type: Boolean,
      default: true
    },

    showLightBox: {
      type: Boolean,
      default: false
    },
    showCloseButton: {
      type: Boolean,
      default: false
    },
    onCloseCallback: {
      type: Function,
      default: function () {
        return true
      }
    },
    closeText: {
      type: String,
      default: 'Close (Esc)'
    }
  },

  data() {
    return {
      lightBoxOn: this.showLightBox
    }
  },

  computed: {},

  watch: {
    lightBoxOn(value) {
      if (document != null) {
        this.onToggleLightBox(value)
      }
    }
  },

  mounted() {},

  beforeDestroy() {},

  methods: {
    onLightBoxOpen() {
      this.$emit('onOpened')

      if (this.disableScroll) {
        document.querySelector('html').classList.add('no-scroll')
      }

      document.querySelector('body').classList.add('lightbox-open')
    },

    onLightBoxClose() {
      this.$emit('onClosed')

      if (this.disableScroll) {
        document.querySelector('html').classList.remove('no-scroll')
      }

      document.querySelector('body').classList.remove('lightbox-open')
    },

    onToggleLightBox(value) {
      if (value) this.onLightBoxOpen()
      else this.onLightBoxClose()
    },
    show() {
      this.lightBoxOn = true
    },
    hide() {
      this.lightBoxOn = false
    },
    closeLightBox() {
      if (this.onCloseCallback()) {
        this.$set(this, 'lightBoxOn', false)
      } else {
        console.log('callback prevented from closing lightbox')
      }
    }
  }
}
</script>

<style>
/* general style */

.lightbox-open {
  overflow: hidden;
}

.no-scroll {
  overflow-y: hidden;
}

.lightbox-hideable {
  transition: opacity 0.5s ease;
}

.lightbox-hidden {
  opacity: 0;
}

/* elements styling */

.lightbox-container {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.85);
  box-sizing: border-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 1500;
  -webkit-align-items: center;
  -moz-box-sizing: border-box;
  -webkit-justify-content: center;
  -ms-flex-align: center;
  -webkit-box-align: center;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
}

.lightbox-content {
  background-color: transparent;
  position: relative;
  display: inline-block;
}

.lightbox-content.xl {
  width: 90%;
  height: 95%;
}

.lightbox-content.m {
  border: 2 solid snow;
  border-radius: 4px;
  background-color: whitesmoke;
  width: 70%;
  height: 45%;
}

.lightbox-close {
  color: white;
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  position: fixed;
  top: 0;
  right: 0;
  vertical-align: bottom;
  height: 65px;
  width: 60px;
  padding: 15px 12px 15px 20px;
}

.lightbox-arrow {
  background: rgba(0, 0, 0, 0.2);
  border: none;
  cursor: pointer;
  outline: none;
  padding: 10px;
  position: absolute;
  top: 50%;
  user-select: none;
  height: 65px;
  margin-top: -60px;
  width: 60px;
}

.lightbox-arrow-left {
  left: 0;
  padding-right: 20px;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.lightbox-arrow-right {
  right: 0;
  padding-left: 20px;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

@media (min-width: 500px) {
  .lightbox-thumbnail-arrow {
    width: 40px;
  }
}

@media (min-width: 768px) {
  .lightbox-arrow {
    width: 60px;
  }
}

/* image transitions */

.lightbox-image-transition-enter-active,
.lightbox-image-transition-leave-active {
  transition: opacity 0.2s ease;
}

.lightbox-image-transition-enter,
.lightbox-image-transition-leave-to {
  opacity: 0;
}

.lightbox-image-no-transition-enter-active,
.lightbox-image-no-transition-leave-active {
  transition: none;
}

.lightbox-image-no-transition-enter,
.lightbox-image-no-transition-leave-to {
  opacity: 0;
}

/* container transitions */

.lightbox-container-transition-enter-active,
.lightbox-container-transition-leave-active {
  transition: opacity 0.3s ease;
}

.lightbox-container-transition-enter,
.lightbox-container-transition-leave-to {
  opacity: 0;
}
</style>
