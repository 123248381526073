import Queue from './Queue'

export default class MediaBatchOperation {
  constructor(media, operation) {
    this.operation = operation
    this.media = media
    this.processed = []
    this.queue = new Queue(1)
    this.progress = 0
    this.observer = undefined
  }

  _notify() {
    if (this.observer) {
      this.observer(this.processed.length, this.progress)
    }
  }

  _itemDone(m) {
    this.processed.push(m)
    this.progress = (this.processed.length / this.media.length) * 100
    this._notify()
  }

  cancel() {
    this.queue.stop()
  }

  start(ob) {
    if (ob) {
      this.observer = ob
    }
    var _this = this

    for (var i = 0; i < this.media.length; i++) {
      const m = this.media[i]
      _this.queue
        .push(function (promise) {
          _this.operation(m, promise)
        })
        .then(
          function () {
            _this._itemDone(m)
          },
          function () {
            _this._itemDone(m)
          }
        )
    }

    _this.queue.start()
  }
}
