<template>
  <b-modal
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
    centered
    :title="title"
    size="md"
    ref="md"
  >
    <b-container fluid>
      <b-progress
        v-if="status == 'tagging' || status == 'done'"
        :value="progress"
        :max="localCopy.length"
        show-progress
        animated
      ></b-progress>
      <div class="modal-container" v-else>
        <b-form-input
          placeholder="add a new tag and press enter"
          type="text"
          autofocus
          ref="addTagInput"
          v-on:keyup.enter="onTagEnter"
          class="mb-1 new-tag-control-input"
          v-model="newTag"
        />
        <tag
          v-for="tag in tags"
          :key="tag"
          :ref="`tag${tag}`"
          :selected="true"
          size="m"
          :selectable="false"
          :value="tag"
          :remove="true"
          :observer="tagObserver()"
        />
        <p v-if="tags.length == 0" class="text-secondary">enter tags</p>
      </div>
    </b-container>
    <template #modal-footer>
      <div class="w-100 justify-content-end d-flex">
        <b-button
          class="mr-1"
          :disabled="status != 'idle'"
          variant="secondary"
          size="sm"
          @click="hide()"
        >
          Cancel
        </b-button>
        <b-button
          :disabled="status != 'idle' || tags.length == 0"
          variant="success"
          size="sm"
          @click="start()"
        >
          Save
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import CollectionUtils from '../../utils/CollectionUtils'
import MediaBatchOperation from '../../utils/MediaBatchOperation'
import api from '../../api'
import Timer from '../../utils/Timer'
import Tag from '../partials/Tag'

export default {
  name: 'tag-media-modal',
  components: {
    Tag
  },
  data() {
    return {
      ops: undefined,
      progress: 0,
      status: 'idle', //tagging, done
      tags: [],
      localCopy: function () {
        return []
      },
      newTag: undefined,
      closeTimer: undefined
    }
  },
  props: {
    media: Array
  },
  mounted() {
    const _this = this
    this.closeTimer = new Timer(2000, function () {
      _this.hide()
    })
    //'delete-tag'
    this.$on('delete-tag', function (tag) {
      CollectionUtils.remove(_this.tags, tag)
    })
  },
  methods: {
    start() {
      this.status = 'tagging'
      const _this = this
      this.ops = new MediaBatchOperation(this.localCopy, function (m, p) {
        api
          .addTags(m.media.id, _this.tags)
          .then(() => {
            p.resolve('tagged')
            _this.$parent.$emit('media-tagged', {
              media: m.media,
              index: m.index
            })
          })
          .catch((error) => {
            p.reject('ERROR_REQUEST_NETWORK_ERROR', error)
          })

        ///
      })

      this.ops.start(function (count) {
        _this.progress = count
      })
    },
    show() {
      this.status = 'idle'
      this.localCopy = Array.from(this.media)
      this.progress = 0
      this.$log.debug('Show delete media dialog for :', this.media)
      this.$refs['md'].show()
    },
    hide() {
      if (this.ops) {
        this.ops.cancel()
        this.ops = undefined
      }
      this.status = 'idle'
      this.$refs['md'].hide()
    },
    tagObserver() {
      return this
    },
    onTagEnter() {
      if (this.newTag && this.newTag.length > 0) {
        this.newTag = this.newTag.toLowerCase().trim()
        CollectionUtils.pushUnique(this.tags, this.newTag)
        this.newTag = ''
      }
    }
  },
  computed: {
    title() {
      return this.status == 'done'
        ? 'Done'
        : this.status == 'tagging'
        ? 'Tagging ' + this.localCopy.length + ' files'
        : 'Add tags to selected files'
    }
  },
  watch: {
    progress(current) {
      if (current == this.localCopy.length) {
        this.status = 'done'
        this.closeTimer.start()
      }
    }
  }
}
</script>
<style scoped>
.modal-container {
  min-height: 10rem;
}
.new-tag-control-input,
.new-tag-control-input::before,
.new-tag-control-input:focus {
  border: 1 !important;
  box-shadow: none !important;
  outline: none !important;
}
</style>
