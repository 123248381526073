<template>
  <b-container class="bg-light m-1">
    <b-row>
      <b-col class="align-self-center mx-auto">
        <b-avatar
          size="3rem"
          src="https://placekitten.com/500/500"
          variant="primary"
        ></b-avatar>
        <span class="ml-2">{{ friend.firstName }} {{ friend.lastName }} </span>
      </b-col>
      <b-col>
        <div class="align-self-center bg-dark">
          <b-button
            @click="selected = !selected"
            v-if="selected"
            size="sm"
            variant="success"
            class="float-right my-auto"
            >deselect</b-button
          >
          <b-button
            v-else
            @click="selected = !selected"
            size="sm"
            variant="secondary"
            class="float-right"
            >select</b-button
          >
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  name: 'friend-item',
  data() {
    return {
      selected: false
    }
  },
  props: {
    friend: undefined,
    observer: undefined
  },
  watch: {
    selected(value) {
      let id = this.friend.id
      this.observer.$emit('friend-selected', {
        selected: value,
        id: id
      })
    }
  }
}
</script>
