<template>
  <div>
    <b-modal
      @ok="handleOk"
      button-size="sm"
      okVariant="success"
      cancelVariant="link"
      :title="`Edit ${name}`"
      static
      centered
      size="sm"
      :id="id"
    >
      <b-container fluid> </b-container>
    </b-modal>
    <span class="text-secondary small"
      ><b-icon :icon="icon" /> {{ name }}
      <b-icon-pencil-square
        @click.stop
        v-b-modal="id"
        id="tooltip-target-1"
        class="edit-button"
        v-if="editable"
    /></span>
    <b-tooltip target="tooltip-target-1" triggers="hover"> edit </b-tooltip>
    <p class="small">{{ value }}</p>
  </div>
</template>
<script>
export default {
  name: 'media-property',
  components: {},
  data: function () {
    return {}
  },
  props: {
    name: String,
    id: String,
    value: String,
    icon: String,
    editable: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleOk(bvModalEvt) {
      console.log(bvModalEvt)
      // Prevent modal from closing
      //bvModalEvt.preventDefault()
      //alert(bvModalEvt)
    }
  }
}
</script>
<style scoped>
.popover-class {
  padding: 0%;
}
</style>
