import { getRandomIntBetween, TArray } from '../utils'

const palette = [
  'f94144',
  'f3722c',
  'f8961e',
  'f9c74f',
  '90be6d',
  '43aa8b',
  '577590'
] //https://coolors.co/f94144-f3722c-f8961e-f9c74f-90be6d-43aa8b-577590

class RandomColor {
  constructor(p) {
    this.palette = new TArray(p)
    this.map = new Map()
  }

  get(tag) {
    if (tag) {
      if (!this.map.has(tag)) {
        let index = getRandomIntBetween(0, this.palette.length() - 1)
        let c = `#${this.palette.get(index)}`
        this.map.set(tag, c)
      }
      return this.map.get(tag)
    }

    let index = getRandomIntBetween(0, this.palette.length() - 1)
    return `#${this.palette.get(index)}`
  }
}

class ColorUtils {
  constructor() {
    this.provider = new RandomColor(palette)
  }
  randomColor() {
    return this.provider
  }
}

export default new ColorUtils()
