<template>
  <b-icon
    v-b-tooltip.hover
    :title="fullScreenMode ? 'exit fullscreen' : 'enter fullscreen'"
    :font-scale="scale"
    v-if="available"
    :icon="fullScreenMode ? 'fullscreen-exit' : 'arrows-fullscreen'"
    @click="toggle()"
  />
</template>
<script>
import screenfull from 'screenfull'
export default {
  data: function () {
    return {
      fullScreenMode: false
    }
  },
  props: {
    scale: {
      type: Number,
      default: 2
    }
  },
  mounted() {
    if (screenfull.isEnabled) {
      let _this = this
      screenfull.on('change', () => {
        console.log('Am I fullscreen?', screenfull.isFullscreen ? 'Yes' : 'No')
        _this.fullScreenMode = screenfull.isFullscreen
      })
    }
  },
  unmounted() {
    screenfull.off('change', () => {})
  },
  computed: {
    available() {
      return screenfull.isEnabled
    }
  },
  methods: {
    toggle() {
      screenfull.toggle()
    }
  }
}
</script>
