<template>
  <div
    @mousemove="mouseMove"
    v-if="visible"
    id="main-gallery-container"
    ref="main-gallery-container"
    class="gallery-container"
  >
    <FullscreenButton
      v-show="controlsVisible"
      class="gallery-fullscreen-button"
    />
    <b-icon-x
      v-if="controlsVisible"
      @click="onClose"
      font-scale="2"
      class="gallery-close-button"
    />
    <b-icon-info-circle
      v-if="controlsVisible"
      @click="onInfo"
      font-scale="2"
      class="gallery-info-button"
    />

    <loader-svg
      color="#ffffff"
      textColor="#ffffff"
      backgroundColor="#000000"
      v-if="loading"
      class="gallery-image"
    />
    <b-img
      v-if="!loading && currentIndex >= 0 && isImage"
      @mouseover="hideControls"
      @mouseleave="showControls"
      ref="main-image-tag"
      class="gallery-image"
      :src="currentImageSrc"
    />
    <video
      v-show="!loading && currentIndex >= 0 && !isImage"
      controls
      class="gallery-image"
      ref="main-video"
    ></video>

    <!-- </div> -->
    <div
      v-if="controlsVisible"
      @click="onBack"
      class="gallery-navigation gallery-navigation-back"
    >
      <b-icon-chevron-left font-scale="2" />
    </div>
    <div
      v-if="controlsVisible"
      @click="onForward"
      class="gallery-navigation gallery-navigation-forward"
    >
      <b-icon-chevron-right font-scale="2" />
    </div>
    <div
      :style="`--thumbsCount: ${visibleThumbsCount}`"
      v-show="controlsVisible && thumbNailsEnabled"
      class="gallery-thumbnails-container"
    >
      <b-img
        :key="item._id"
        v-for="(item, index) in thumbs"
        :src="getCurrentThumbSrc(item)"
        :ref="`thumb-${item.id}`"
        @click="onThumbClicked(index)"
        :style="{ '--index': index, '--thumbsCount': visibleThumbsCount }"
        v-bind:class="{ selected: index == selectedThumb }"
        class="gallery-thumbnail"
      />
    </div>
    <ImageInfoModal
      v-if="currentIndex > -1"
      ref="galleryinfo"
      :media="current"
    />
  </div>
</template>
<script>
import Hls from 'hls.js'
import Timer from '../../utils/Timer'
import ImageInfoModal from '../partials/ImageInfoModal'
import FullscreenButton from '../partials/FullscreenButton'
import LoaderSvg from '../partials/LoaderSvg'
import api from '../../api'
import config from '../../config'

export default {
  components: {
    ImageInfoModal,
    FullscreenButton,
    LoaderSvg
  },
  data() {
    return {
      //the index of the media in the media array that corresponds to the first thumbnail displayed
      firstVisibleThumb: 0,
      // max number of thumbnails to show
      maxVisibleThumbsCount: 15,
      // switch thumbnails page
      switchThumbNailPageIndex: 2,
      //are the navigation controls visible ?
      controlsVisible: true,
      //the index in the media array of the current displayed media item
      currentIndex: -1,
      //define if this gallery is visible or not
      visible: false,
      containerWidth: '100%',
      containerHeight: '100%',
      controlsTimer: undefined,
      loading: true,
      thumbsSrc: new Map(),
      //map blob urls to mediaids
      imagesSrc: new Map(),
      currentImageSrc: undefined,
      hls: undefined
    }
  },
  props: {},
  mounted() {
    this.controlsTimer = new Timer(2000, this.hideControls)
  },
  computed: {
    media() {
      return this.$store.state.media.gallery
    },
    startFrom() {
      return this.$store.state.media.galleryIndex
    },
    isImage() {
      return this.current.type.match(/image.*/)
    },
    mediaAspectRatio() {
      return this.current.dimension.width / this.current.dimension.height
    },
    visibleThumbsCount() {
      return this.maxVisibleThumbsCount > this.media.length
        ? this.media.length
        : this.maxVisibleThumbsCount
    },
    selectedThumb() {
      return this.currentIndex - this.firstVisibleThumb
    },
    thumbs() {
      if (
        this.firstVisibleThumb + this.visibleThumbsCount <
        this.media.length
      ) {
        return this.media.slice(
          this.firstVisibleThumb,
          this.firstVisibleThumb + this.visibleThumbsCount
        )
      } else {
        return this.media.slice(this.firstVisibleThumb)
      }
    },
    thumbNailsEnabled() {
      return this.media.length > 1
    },
    current: function () {
      return this.media[this.currentIndex]
    }
  },
  methods: {
    show() {
      this.visible = true
      const _this = this
      setTimeout(function () {
        _this.currentIndex = _this.startFrom
      }, 1)
    },
    reset() {
      this.firstVisibleThumb = 0
      this.currentIndex = -1
      this.currentImageSrc = undefined
    },
    onInfo() {
      this.$refs['galleryinfo'].show()
    },
    onClose() {
      this.reset()
      this.$refs['galleryinfo'].hide()
      this.visible = false
    },
    onBack() {
      if (this.currentIndex == 0) {
        this.currentIndex = this.media.length - 1
      } else {
        this.currentIndex = this.currentIndex - 1
      }
    },
    onForward() {
      if (this.currentIndex == this.media.length - 1) {
        this.currentIndex = 0
      } else {
        this.currentIndex = this.currentIndex + 1
      }
    },
    onThumbClicked(index) {
      this.currentIndex = index + this.firstVisibleThumb
    },
    hideControls() {
      this.controlsVisible = false
    },
    showControls() {
      this.controlsVisible = true
    },
    mouseMove() {
      this.showControls()
      this.controlsTimer.start()
    },
    getCurrentThumbSrc(media) {
      if (this.thumbsSrc[media.id]) {
        return this.thumbsSrc[media.id]
      }

      let location = media.location
      let w = 100 /*this.computedWidth*/
      let h = 100 /*this.computedHeight*/
      let type = 'thumb'

      let cb = (src, error) => {
        if (error) {
          this.$log.debug('error loading thumb', error)
        }
        this.thumbsSrc[media.id] = src
        this.$refs[`thumb-${media.id}`].src = src
      }

      api.getImage({ location, w, h, type, cb })

      return ''
    },
    getCurrentImageSrc(index) {
      var video = this.$refs['main-video']
      if (video) {
        video.pause()
      }
      this.loading = true
      setTimeout(() => {
        let media = this.media[index]

        if (this.imagesSrc[media.id]) {
          this.currentImageSrc = this.imagesSrc[media.id]
          this.loading = false
          return
        }

        let location = media.location
        let w = undefined /*this.computedWidth*/
        let h = undefined /*this.computedHeight*/
        let type = undefined

        let cb = (src, error) => {
          if (error) {
            this.$log.debug('error loading image', error)
          }
          this.loading = false
          this.imagesSrc[media.id] = src
          //call this again with currentIndex so we set it only if the user is still on that image
          this.getCurrentImageSrc(this.currentIndex)
        }
        cb = cb.bind(this)
        api.getImage({ location, w, h, type, cb })
      }, 5)
    },
    _ensureHLSReady() {
      if (Hls.isSupported()) {
        let _this = this
        var video = this.$refs['main-video']
        video.pause()
        var hls = new Hls({
          debug: true,
          xhrSetup: function (xhr, url) {
            _this.$log.debug('preparing video request ', url)
            api.prepareXMLHttpRequest(xhr)
          }
        })

        hls.loadSource(config.getVideoStreamPlaylist(_this.current))
        hls.attachMedia(video)
        hls.on(Hls.Events.MEDIA_ATTACHED, function () {
          _this.loading = false
          video.muted = false
          video.play()
        })
      }
    },
    _containerWidth() {
      let landscape = this.mediaAspectRatio > 1
      //let w = 100 * this.mediaAspectRatio
      if (landscape) {
        return Math.min(
          this.current.dimension.width,
          this.$refs['main-gallery-container'].offsetWidth
        )
      } else {
        return this._containerHeight() * this.mediaAspectRatio
      }
    },
    _containerHeight() {
      let landscape = this.mediaAspectRatio > 1
      if (landscape) {
        return this._containerWidth() * this.mediaAspectRatio
      } else {
        return Math.min(
          this.current.dimension.height,
          this.$refs['main-gallery-container'].offsetHeight
        )
      }
    }
  },
  watch: {
    currentIndex(current) {
      this.controlsTimer.start()
      this.showControls()
      this.containerWidth = this._containerWidth() + 'px'
      this.containerHeight = this._containerHeight() + 'px'
      if (this.isImage) {
        this.getCurrentImageSrc(current)
      } else {
        this.$nextTick(() => {
          this._ensureHLSReady()
        })
      }

      if (current == this.firstVisibleThumb && this.firstVisibleThumb > 0) {
        this.firstVisibleThumb--
      } else if (
        current == this.firstVisibleThumb + this.visibleThumbsCount - 1 &&
        current < this.media.length - 1
      ) {
        this.firstVisibleThumb++
      }
    }
  }
}
</script>
<style scoped>
.gallery-container {
  align-items: center;
  background-color: rgba(0, 0, 0, 1);
  box-sizing: border-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 1022;
  -webkit-align-items: center;
  -moz-box-sizing: border-box;
  -webkit-justify-content: center;
  -ms-flex-align: center;
  -webkit-box-align: center;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
}

.gallery-inner-container {
  position: relative;
  display: inline-block;
  background-color: transparent;
  width: 100%;
  height: 100%;
}
.gallery-close-button {
  cursor: pointer;
  color: white;
  position: absolute;
  margin: 10px;
  top: 0;
  right: 0;
  background-color: transparent;
  width: 25px;
  height: 25px;
  z-index: 1000;
  opacity: 0.3;
}

.gallery-close-button:hover {
  scale: 1.2;
  opacity: 1;
}

.gallery-fullscreen-button {
  cursor: pointer;
  color: white;
  position: absolute;
  margin: 10px;
  top: 0;
  right: 40px;
  background-color: transparent;
  width: 20px;
  height: 20px;
  z-index: 2000;
  opacity: 0.3;
}
.gallery-fullscreen-button:hover {
  opacity: 1;
}

.gallery-info-button {
  cursor: pointer;
  color: white;
  position: absolute;
  margin: 10px;
  bottom: 0;
  right: 0;
  background-color: transparent;
  width: 18px;
  height: 18px;
  z-index: 1000;
  opacity: 0.3;
}
.gallery-info-button:hover {
  scale: 1.2;
  opacity: 1;
}

.gallery-image {
  max-width: 100%;
  max-height: 100%;
}

.gallery-navigation {
  z-index: 999;
  cursor: pointer;
  color: white;
  background-color: transparent;
  width: 25px;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: absolute;
  opacity: 0.3;
  display: flex;
}
.gallery-navigation:hover {
  opacity: 1;
}
.gallery-navigation-back {
  left: 0;
  top: 0;
}

.gallery-navigation-forward {
  right: 0;
  top: 0;
}

.gallery-thumbnails-container {
  --height: 50px;
  --thumbsCount: 5;
  background-color: rgba(255, 255, 255, 0.5);
  border: 0px solid rgba(255, 255, 255, 0.5);
  border-radius: 2px;
  position: absolute;
  top: 5px;
  height: var(--height);
  width: calc(var(--height) * var(--thumbsCount));
  overflow: visible;
}
.gallery-thumbnail {
  --thumbsCount: 5;
  --index: 0;
  opacity: 0.5;
  cursor: pointer;
  padding: 0px;
  left: calc(100% / var(--thumbsCount) * var(--index));
  position: absolute;
  height: 100%;
  width: calc(100% / var(--thumbsCount));
}
.gallery-thumbnail:hover {
  opacity: 1;
}
.gallery-thumbnail.selected {
  border: 2px solid white;
  opacity: 1;
}

.media-container {
  --w: 100%;
  --h: 100%;
  border: 4px solid red;
  position: absolute;
  display: block;
  width: var(--w);
  height: var(--h);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
</style>
